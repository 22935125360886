import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  options_search,
  GuestManagementPageText,
} from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_all_restaurant_owner,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleEmailChange,
  handleNumbersChange,
  computeplussevendays,
} from "../CommonJquery/CommonJquery.js";
function RestaurantEnquiry() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(get_all_restaurant_owner, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_customerInformation.forEach((subObj) => {
            if (subObj.guest_status === 1) {
              subObj.vipmarkdata = "vip";
            } else {
              subObj.vipmarkdata = "";
            }
          });
          seteditStaffData(Response.data.message.data_customerInformation);
          setfilteredData(Response.data.message.data_customerInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const exportToCSV = (csvData, fileName, fileExtension) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const openModal = (guestName, index) => {
    setSelectedData(guestName);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {showLoaderAdmin && <Loader />}

      <div className="dashboard" style={{ overflow: "hidden" }}>
        <div className="dashboard_container">
          <div className="page_content">
            <div className="page_content_container">
              <Header />
              <div className="pageCntn_head">
                <div className="pageNameDiv">
                  <p>Restaurant Enquiry Management</p>
                  <img src={GreyArrow} alt="Dfoodo Dashboard" />
                </div>
                <div
                  className="col-sm-3 mr-5
              alignCol bottomAlgin page_body"
                >
                  <div className="pageActionBtns">
                    <button
                      className="CSVBTN"
                      onClick={() =>
                        exportToCSV(filteredData, "download_excel", ".xlsx")
                      }
                    >
                      Download Excel
                    </button>
                  </div>
                </div>
              </div>

              <div className="page_body">
                <div className="viewStaff">
                  <div className="viewStaff_head">
                    <div className="row m-0">
                      <div className="col-lg-2 col-sm-4 bottomAlgin">
                        <label className="labelView">
                          {GuestManagementPageText.Start_Date}
                        </label>
                        <div className="person__calenderFrame_image image_icon_position1 ">
                          <input
                            id="startDate"
                            type="date"
                            placeholder={GuestManagementPageText.D_O_B}
                            className="form-control  input_field_custom4"
                            defaultValue={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            max={computeTodayDate()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-4 bottomAlgin">
                        <label className="labelView">
                          {GuestManagementPageText.End_Date}
                        </label>
                        <div className="person__calenderFrame_image image_icon_position1 ">
                          <input
                            id="endDate"
                            type="date"
                            placeholder={GuestManagementPageText.D_O_B}
                            className="form-control  input_field_custom4"
                            defaultValue={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            max={computeTodayDate()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-4 bottomAlgin">
                        <div className="inputDiv2">
                          <label
                            className="labelPointer"
                            htmlFor="selectFilter"
                          >
                            <img src={FilterIcon} alt="Dfoodo Dashboard" />
                          </label>
                          <select id="selectFilter" onChange={handleSelect}>
                            {options_search.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-5 col-sm-8 bottomAlgin">
                        <div className="inputDiv2">
                          <img src={Search} alt="Dfoodo Dashboard" />
                          <input
                            type="text"
                            id="search_data"
                            maxLength={
                              selectedValue === options_search[2].value
                                ? 10
                                : undefined
                            }
                            onInput={(e) => {
                              if (selectedValue === options_search[0].value) {
                                // handleAphabetsChange(e);
                              } else if (
                                selectedValue === options_search[1].value
                              ) {
                                handleEmailChange(e);
                              } else if (
                                selectedValue === options_search[2].value
                              ) {
                                handleNumbersChange(e);
                              }
                            }}
                            placeholder={GuestManagementPageText.Search_Guest}
                          />
                          <button
                            type="button"
                            className="btnSearch"
                            onClick={() => search_data()}
                          >
                            {GuestManagementPageText.Search_text}
                          </button>
                        </div>
                      </div>

                      <div
                        className="col-xl-3 col-md-4"
                        style={{ marginLeft: "auto", marginRight: "0" }}
                      >
                        <div className="inputDiv2">
                          <label
                            className="labelPointer"
                            htmlFor="selectFilter"
                          >
                            <img src={FilterIcon} alt="Dfoodo Dashboard" />
                          </label>
                          <input
                            type="text"
                            placeholder={GuestManagementPageText.Guest_Filter}
                            value={searchfilter}
                            onInput={handleIaphabetnumberChange}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="viewGuest_table ">
                    <div className="viewGuest_table_container ">
                      <div className="row m-0">
                        <div className="col-md-12 stickyTableheight">
                          <table className="table">
                            <thead className="stickyHeaderClss">
                              <tr>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>S.No</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <img
                                      src={GuestIcon}
                                      alt="Dfoodo Dashboard"
                                    />
                                    <span>
                                      {GuestManagementPageText.Guest_Name}
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">
                                    {GuestManagementPageText.Contact_Details}
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">
                                    {GuestManagementPageText.Email_ID}
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">Restaurant</div>
                                </th>

                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="tboday">
                              {filteredData.map((option, index) => (
                                <React.Fragment key={index}>
                                  <tr className="tableRow tbodyStyle">
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {index + 1}
                                      </div>
                                    </td>
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {option.Owner_fname}{" "}
                                        {option.Owner_lname}
                                      </div>
                                    </td>
                                    <td className="tabledata">
                                      <div className="guest_incenter shadowOnlyBottom">
                                        {option.Contact}
                                      </div>
                                    </td>
                                    <td className="tabledata">
                                      <div className="guest_incenter shadowOnlyBottom">
                                        {option.Email}
                                      </div>
                                    </td>
                                    <td className="tabledata">
                                      <div className="guest_incenter shadowOnlyBottom">
                                        {option.restaurant_name}
                                      </div>
                                    </td>

                                    <td className="tabledata">
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "#666666",
                                          borderRadius: "1rem",
                                          border: "none",
                                          padding: "0.2rem 0.4rem",
                                        }}
                                        onClick={() => openModal(option, index)}
                                      >
                                        <p
                                          style={{
                                            margin: "0 2rem",
                                            color: "#ffffff",
                                          }}
                                        >
                                          Details
                                        </p>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr
                                    key={`spacer-${index}`}
                                    style={{ height: "1rem" }}
                                  ></tr>
                                </React.Fragment>
                              ))}
                              {filteredData.length === 0 && (
                                <tr>
                                  <td colSpan={5} className="text_align_center">
                                    No Results Found
                                  </td>{" "}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              {/* ... Modal content goes here ... */}
            </div>
            <div className="">
              <div className="d-flex" style={{ gap: "5px" }}>
                <p>Address : </p>
                <p>{SelectedData.restaurant_address}</p>
              </div>
              <div className="d-flex" style={{ gap: "5px" }}>
                <p>Website :</p>
                <p>{SelectedData.restaurant_website}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="logoutNoBtn" onClick={closeModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default RestaurantEnquiry;
