import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import update from "../assets/editBlack.svg";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import { GuestManagementPageText } from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  classify_restaurant_subscriptions,
  save_restaurant_subscription,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  computeplussevendays,
  combiled_form_data,
  handleNumbersChange,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import Select from "react-select";
function SubscriptionManagement() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(""); // State to hold the selected value
  const [restuarnt, setrestuarnt] = useState([]);
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    await server_post_data(classify_restaurant_subscriptions, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredData(Response.data.message.classified_restaurants);
          seteditStaffData(Response.data.message.classified_restaurants);
          setrestuarnt(Response.data.message.reservation_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        console.log(error);
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedGuestIndexData, setSelectedGuestIndexData] = useState(null);

  const openModal = (guestData) => {
    setSelectedData(guestData);
    setShowModal(true);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const openEditModal = (data) => {
    setShowEditModal(true);
    setSelectedGuestIndexData(filteredData[data]);
  };
  const openShowModal = () => {
    setShowAddModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setShowAddModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = async (form_data, url_for_save) => {
    if (selectedGuestIndexData && selectedGuestIndexData.main_id) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      // let fd_from = form_data;

      // Append the main_id to the formData
      fd_from.append("main_id", selectedGuestIndexData.main_id);

      await server_post_data(url_for_save, fd_from) // Replace with your actual API endpoint
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            // console.log("go inside")
            closeEditModal(); // Close the modal after saving
            handleSuccess(Response.data.message);
            master_data_get(startDate, endDate, "1", "0"); // Refresh the data
          }
        })
        .catch((error) => {
          console.log(error);
          handleError("network");
          setshowLoaderAdmin(false);
        });
    } else {
      console.log("No Main ID found");
    }
  };

  const handleSaveChanges_add = async (form_data, url_for_save) => {
    setshowLoaderAdmin(true);
    let fd_from = combiled_form_data(form_data, null);
    fd_from.append("restaurant_id", selectedValue);
    await server_post_data(url_for_save, fd_from)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          closeEditModal(); // Close the modal after saving
          master_data_get(startDate, endDate, "1", "0"); // Refresh the data
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const handleChange1 = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };
  return (
    <>
      {showLoaderAdmin && <Loader />}{" "}
      <div className="dashboard" style={{ overflow: "hidden" }}>
        <div className="dashboard_container">
          <div className="page_content">
            <div className="page_content_container">
              <Header />
              <div className="pageCntn_head">
                <div className="pageNameDiv">
                  <p>Subscription Management</p>
                  {/* <p>{GuestManagementPageText.Guest_Management}</p> */}
                  <img src={GreyArrow} alt="Barley's Dashboard" />
                </div>
                <div className="bottomAlgin " style={{ margin: "0 3rem" }}>
                  <button
                    type="button"
                    className="btnAddStaff add_staff"
                    onClick={openShowModal}
                  >
                    Add Subscription
                  </button>
                </div>
              </div>

              <div className="page_body">
                <div className="viewStaff">
                  <div className="viewStaff_head">
                    <div className="row m-0">
                      <div className="col-lg-2 col-sm-4 bottomAlgin">
                        <label className="labelView">
                          {GuestManagementPageText.Start_Date}
                        </label>
                        <div className="person__calenderFrame_image image_icon_position1 ">
                          <input
                            id="startDate"
                            type="date"
                            placeholder={GuestManagementPageText.D_O_B}
                            className="form-control  input_field_custom4"
                            defaultValue={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            max={computeTodayDate()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-4 bottomAlgin">
                        <label className="labelView">
                          {GuestManagementPageText.End_Date}
                        </label>
                        <div className="person__calenderFrame_image image_icon_position1 ">
                          <input
                            id="endDate"
                            type="date"
                            placeholder={GuestManagementPageText.D_O_B}
                            className="form-control  input_field_custom4"
                            defaultValue={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            max={computeTodayDate()}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 mb-2 col-sm-1 bottomAlgin">
                        <div
                          className="inputDiv2"
                          style={{
                            boxShadow: "none",
                            padding: 0,
                            backgroundColor: "transparent",
                          }}
                        >
                          <button
                            type="button"
                            className="btnSearch"
                            onClick={() => search_data()}
                            style={{
                              height: "40px",
                              position: "static",
                              right: "0",
                              top: "0",
                            }}
                          >
                            {GuestManagementPageText.Search_text}
                          </button>
                        </div>
                      </div>

                      <div
                        className="col-xl-3 col-md-4 mt-4"
                        style={{ marginLeft: "auto", marginRight: "0" }}
                      >
                        <div className="inputDiv2">
                          <label
                            className="labelPointer"
                            htmlFor="selectFilter"
                          >
                            <img src={FilterIcon} alt="Barley's Dashboard" />
                          </label>
                          <input
                            type="text"
                            placeholder={GuestManagementPageText.Guest_Filter}
                            value={searchfilter}
                            onInput={handleIaphabetnumberChange}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="viewGuest_table ">
                    <div className="viewGuest_table_container ">
                      <div className="row m-0">
                        <div className="col-md-12 table-responsive stickyTableheight">
                          <table className="table">
                            <thead className="stickyHeaderClss">
                              <tr>
                                <th scope="col">
                                  <div className="theadStyle imgThead">
                                    <span>S.No.</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead">
                                    <img src={GuestIcon} alt="Dashboard" />
                                    <span>Name</span>
                                  </div>
                                </th>
                                {/* <th scope="col">
                                <div className="theadStyle">Location</div>
                              </th> */}
                                <th scope="col">
                                  <div className="theadStyle">
                                    Subscribed Since
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">Tenure</div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">
                                    Next Renewal Date
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle"></div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tboday">
                              {filteredData.map((option, index) => (
                                <React.Fragment key={index}>
                                  <tr className="tableRow tbodyStyle">
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {index + 1}
                                      </div>
                                    </td>
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {option.restaurant_name}
                                      </div>
                                    </td>
                                    {/* <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {`${option.restaurant_city || ""}, ${
                                        option.restaurant_state || ""
                                      }, ${
                                        option.restaurant_country || ""
                                      }`.replace(/(^[,\s]+)|([,\s]+$)/g, "") ||
                                        "N/A"}
                                    </div>
                                  </td> */}
                                    <td className="tabledata">
                                      <div className="guest_incenter shadowOnlyBottom">
                                        {option.subscription_start_date
                                          ? `${option.subscription_start_date} (${option.subscription_duration_days} days)`
                                          : "N/A"}
                                      </div>
                                    </td>
                                    <td className="tabledata">
                                      <div className="guest_incenter shadowOnlyBottom">
                                        {option.subscription_period
                                          ? `${option.subscription_period} days`
                                          : "N/A"}
                                      </div>
                                    </td>
                                    <td className="tabledata">
                                      <div className="guest_incenter shadowOnlyBottom">
                                        {option.subscription_next_renewal_date ||
                                          "N/A"}
                                      </div>
                                    </td>
                                    <td className="tabledata d-flex align-items-center mb-0 m-1">
                                      <div className="img-icons">
                                        <button
                                          type="button"
                                          style={{
                                            backgroundColor: "#666666",
                                            borderRadius: "1rem",
                                            border: "none",
                                            padding: "0.2rem 0.4rem",
                                          }}
                                          onClick={() =>
                                            openModal(option, index)
                                          }
                                        >
                                          <p
                                            style={{
                                              margin: "0 2rem",
                                              color: "#ffffff",
                                            }}
                                          >
                                            Details
                                          </p>
                                        </button>
                                        &nbsp; &nbsp;
                                        <img
                                          onClick={() => openEditModal(index)}
                                          className=""
                                          src={update}
                                          alt="update"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    key={`spacer-${index}`}
                                    style={{ height: "1rem" }}
                                  ></tr>
                                </React.Fragment>
                              ))}
                              {filteredData.length === 0 && (
                                <>
                                  <tr>
                                    <td
                                      colSpan={6}
                                      className="text_align_center"
                                    >
                                      No Results Found
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body justify-content-start">
            <div className="success_img bodyText">
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Name :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.restaurant_name}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"City :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.restaurant_city}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"State :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.restaurant_state}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Country :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.restaurant_country}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Subscribed Since :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>
                    {" "}
                    {SelectedData.subscription_start_date}{" "}
                    {SelectedData.subscription_duration_days} {"Days"}
                  </p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Latest Renewal Date :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.subscription_last_renewal_date}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Tenure :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.subscription_period}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Next Due Date :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.subscription_next_renewal_date}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="logoutNoBtn hoverRed" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showEditModal}
          onHide={closeEditModal}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Edit Subscription (
              {selectedGuestIndexData && selectedGuestIndexData.restaurant_name}
              )
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedGuestIndexData && (
              <form id="editSubsData">
                <div className="form-group">
                  <label
                    style={{ fontWeight: "500" }}
                    htmlFor="subscription_Last_renewal_date"
                  >
                    Latest Subscription Date
                  </label>
                  <input
                    style={{ marginBottom: "0.5rem" }}
                    type="date"
                    className="form-control"
                    id="subscription_Last_renewal_date"
                    name="subscription_Last_renewal_date"
                    defaultValue={
                      selectedGuestIndexData.subscription_last_renewal_date ||
                      ""
                    }
                    onChange={(e) => {
                      setSelectedData({
                        ...SelectedData,
                        restaurant_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label
                    style={{ fontWeight: "500" }}
                    htmlFor="subscription_renewal_date"
                  >
                    Next Due Date
                  </label>
                  <input
                    style={{ marginBottom: "0.5rem" }}
                    type="date"
                    className="form-control"
                    id="subscription_renewal_date"
                    name="subscription_renewal_date"
                    defaultValue={
                      selectedGuestIndexData.subscription_next_renewal_date ||
                      ""
                    }
                    onChange={(e) => {
                      setSelectedData({
                        ...SelectedData,
                        restaurant_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label
                    style={{ fontWeight: "500" }}
                    htmlFor="subscription_amount"
                  >
                    Subscription Amount
                  </label>
                  <input
                    style={{ marginBottom: "0.5rem" }}
                    type="text"
                    className="form-control"
                    onInput={handleNumbersChange}
                    id="subscription_amount"
                    name="subscription_amount"
                    // placeholder="enter amount"
                    defaultValue={
                      selectedGuestIndexData.subscription_amount || ""
                    }
                    onChange={(e) => {
                      setSelectedData({
                        ...SelectedData,
                        restaurant_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label
                    style={{ fontWeight: "500" }}
                    htmlFor="subscription_period"
                  >
                    Subscription Period (In Day's)
                  </label>
                  <input
                    style={{ marginBottom: "0.5rem" }}
                    type="text"
                    className="form-control"
                    id="subscription_period"
                    onInput={handleNumbersChange}
                    name="subscription_period"
                    // placeholder="No of Days"
                    defaultValue={
                      selectedGuestIndexData.subscription_period || ""
                    }
                    onChange={(e) => {
                      setSelectedData({
                        ...SelectedData,
                        restaurant_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditModal}>
              Close
            </Button>
            <Button
              variant="primary"
              className="logoutNoBtn"
              onClick={() =>
                handleSaveChanges("editSubsData", save_restaurant_subscription)
              }
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showAddModal}
          onHide={closeEditModal}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="AddSubsData">
              <div className="form-group">
                <label style={{ fontWeight: "500" }} htmlFor="restaurantName">
                  Restaurant Name
                </label>
                <Select
                  style={{ marginBottom: "0.5rem" }}
                  id="select_category"
                  name="admin_category"
                  className=" input_field_custom1_add_restro"
                  placeholder="Select restaurant"
                  options={
                    restuarnt &&
                    restuarnt.length > 0 &&
                    restuarnt.map((option) => ({
                      value: option.primary_id,
                      label:
                        option.restaurant_name +
                        "(" +
                        option.restaurant_city +
                        ")",
                    }))
                  }
                  onChange={handleChange1}
                />
              </div>

              <div className="form-group">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="subscription_period"
                >
                  Order ID
                </label>
                <input
                  style={{ marginBottom: "0.5rem" }}
                  type="text"
                  className="form-control"
                  id="order_id"
                  name="order_id"
                  onChange={(e) => {
                    setSelectedData({
                      ...SelectedData,
                      restaurant_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="subscription_period"
                >
                  Transaction ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="transaction_id"
                  name="transaction_id"
                  style={{ marginBottom: "0.5rem" }}
                  onChange={(e) => {
                    setSelectedData({
                      ...SelectedData,
                      restaurant_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="subscription_amount"
                >
                  Subscription Amount
                </label>
                <input
                  style={{ marginBottom: "0.5rem" }}
                  type="text"
                  className="form-control"
                  id="subscription_amount"
                  onInput={handleNumbersChange}
                  name="subscription_amount"
                  maxLength={5}
                  onChange={(e) => {
                    setSelectedData({
                      ...SelectedData,
                      restaurant_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="subscription_period"
                >
                  Subscription Period (In Day's)
                </label>
                <input
                  style={{ marginBottom: "0.5rem" }}
                  type="text"
                  onInput={handleNumbersChange}
                  className="form-control"
                  id="subscription_period"
                  name="subscription_period"
                  // placeholder="No of Days"
                  maxLength={3}
                  onChange={(e) => {
                    setSelectedData({
                      ...SelectedData,
                      restaurant_name: e.target.value,
                    });
                  }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditModal}>
              Close
            </Button>
            <Button
              variant="primary"
              className="logoutNoBtn"
              onClick={() =>
                handleSaveChanges_add(
                  "AddSubsData",
                  save_restaurant_subscription
                )
              }
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default SubscriptionManagement;
