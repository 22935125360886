import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import update from "../assets/editBlack.svg";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import { GuestManagementPageText } from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_landmark_data,
  active_status_super_master,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  computeplussevendays,
  combiled_form_data,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery.js";
import Select from "react-select";
import { Link } from "react-router-dom";
function LandMarkView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(""); // State to hold the selected value
  const [restuarnt, setrestuarnt] = useState([]);
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag);
  };

  const master_data_get = async (start_date, end_date, flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    await server_post_data(get_landmark_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message);
          setfilteredData(Response.data.message.data_reviews);
          seteditStaffData(Response.data.message.data_reviews);
          // setrestuarnt(Response.data.message.reservation_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        console.log(error);
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [selectedGuestIndexData, setSelectedGuestIndexData] = useState(null);

  const openModal = (guestData) => {
    setSelectedData(guestData);
    setShowModal(true);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const openEditModal = (data) => {
    setShowEditModal(true);
    setSelectedGuestIndexData(filteredData[data]);
  };
  const openShowModal = () => {
    setShowAddModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeModal1 = () => {
    setShowModal1(false);
  };
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(1);

  const openModal1 = (guestName, index) => {
    let tesr_show = " Not ";
    if (guestName.guest_status === 0) {
      tesr_show = "";
    }
    if (index === 1) {
      tesr_show = tesr_show + "  as VIP ";
    } else if (index === 2) {
      tesr_show = " Not ";
      if (guestName.active_status === 0) {
        tesr_show = "";
      }
      tesr_show = tesr_show + "  as Active ";
    }
    setSelectedData(guestName);
    setShowModal1(true);
    setClickedButton(guestName.city + tesr_show);
    setSelectedGuestIndex(index);
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_active", call_id);
    fd.append("flag_for", 5);
    fd.append("for_status_final", for_status_final);
    await server_post_data(active_status_super_master, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal1();
          master_data_get(startDate, endDate, "1");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const confirmVIP = (index) => {
    let allow_access_data = "0";
    if (selectedGuestIndex === 1) {
      if (SelectedData.guest_status === 0) {
        allow_access_data = "1";
      }
    } else {
      if (SelectedData.active_status === 0) {
        allow_access_data = "1";
      }
    }

    master_data_action_update(SelectedData.primary_id, allow_access_data);
  };
  const handleSaveChanges = async (form_data, url_for_save) => {
    if (selectedGuestIndexData && selectedGuestIndexData.main_id) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      // let fd_from = form_data;

      // Append the main_id to the formData
      fd_from.append("main_id", selectedGuestIndexData.main_id);

      await server_post_data(url_for_save, fd_from) // Replace with your actual API endpoint
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            closeEditModal(); // Close the modal after saving
            master_data_get(startDate, endDate, "1", "0"); // Refresh the data
          }
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    } else {
      console.log("No Main ID found");
    }
  };

  const handleSaveChanges_add = async (form_data, url_for_save) => {
    setshowLoaderAdmin(true);
    let fd_from = combiled_form_data(form_data, null);
    fd_from.append("restaurant_id", selectedValue);
    await server_post_data(url_for_save, fd_from)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeEditModal(); // Close the modal after saving
          master_data_get(startDate, endDate, "1", "0"); // Refresh the data
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const handleChange1 = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };
  return (
    <>
      {showLoaderAdmin && <Loader />}
      <div className="dashboard" style={{ overflow: "hidden" }}>
        <div className="dashboard_container">
          <div className="page_content">
            <div className="page_content_container">
              <Header />
              <div className="pageCntn_head">
                <div className="pageNameDiv">
                  <p>Area Management</p>
                  <img src={GreyArrow} alt="Barley's Dashboard" />
                </div>
                <div className="bottomAlgin ">
                  <Link to="/add_landmark" style={{ marginRight: "2.75rem" }}>
                    <button type="button" className="btnAddStaff add_staff">
                      Add Area
                    </button>
                  </Link>
                </div>
              </div>

              <div className="page_body">
                <div className="viewStaff">
                  <div className="viewStaff_head">
                    <div className="row m-0">
                      <div className="col-lg-2 col-sm-4 bottomAlgin">
                        <label className="labelView">
                          {GuestManagementPageText.Start_Date}
                        </label>
                        <div className="person__calenderFrame_image image_icon_position1 ">
                          <input
                            id="startDate"
                            type="date"
                            placeholder={GuestManagementPageText.D_O_B}
                            className="form-control  input_field_custom4"
                            defaultValue={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            max={computeTodayDate()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-4 bottomAlgin">
                        <label className="labelView">
                          {GuestManagementPageText.End_Date}
                        </label>
                        <div className="person__calenderFrame_image image_icon_position1 ">
                          <input
                            id="endDate"
                            type="date"
                            placeholder={GuestManagementPageText.D_O_B}
                            className="form-control  input_field_custom4"
                            defaultValue={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            max={computeTodayDate()}
                          />
                        </div>
                      </div>

                      <div className="col-lg-1 col-sm-1 bottomAlgin">
                        <div className="inputDiv2 inputDiv3333">
                          <button
                            type="button"
                            className="btnSearch"
                            onClick={() => search_data()}
                          >
                            {GuestManagementPageText.Search_text}
                          </button>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 col-md-4 mt-3"
                        style={{ marginLeft: "auto", marginRight: "0" }}
                      >
                        <div className="inputDiv2">
                          <label
                            className="labelPointer"
                            htmlFor="selectFilter"
                          >
                            <img src={FilterIcon} alt="Barley's Dashboard" />
                          </label>
                          <input
                            type="text"
                            placeholder={GuestManagementPageText.Guest_Filter}
                            value={searchfilter}
                            onInput={handleIaphabetnumberChange}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="viewGuest_table ">
                    <div className="viewGuest_table_container  ">
                      <div className="row m-0">
                        <div className="col-md-12 stickyTableheigh290">
                          <table className="table">
                            <thead className="stickyHeaderClss">
                              <tr>
                                <th scope="col">
                                  <div className="theadStyle imgThead">
                                    <span>S.No.</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead">
                                    <span>Landmark Name</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead">
                                    <span>City</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">Country</div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">Status</div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle"></div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tboday">
                              {filteredData.map((option, index) => (
                                <React.Fragment key={index}>
                                  <tr className="tableRow tbodyStyle">
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {index + 1}
                                      </div>
                                    </td>
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {option.landmark_name}
                                      </div>
                                    </td>
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {option.city}
                                      </div>
                                    </td>
                                    <td className="th1 tabledata">
                                      <div className="guest_incenter borderLeftRadius">
                                        {option.country}
                                      </div>
                                    </td>

                                    <button
                                      type="button"
                                      style={{ margin: " 1rem 0" }}
                                      className={`markVip Mark_Vip ${
                                        option.active_status === 1
                                          ? "vipMarked"
                                          : "vipMarkedred"
                                      }`}
                                      onClick={() => openModal1(option, 2)}
                                    >
                                      <p style={{ margin: " 0 1rem" }}>
                                        {option.active_status === 1
                                          ? "Active"
                                          : "Deactive"}
                                      </p>
                                    </button>

                                    <td className="tabledata">
                                      <div className="img-icons">
                                        <button
                                          type="button"
                                          style={{
                                            backgroundColor: "#666666",
                                            borderRadius: "1rem",
                                            border: "none",
                                            padding: "0.2rem 0.4rem",
                                          }}
                                          onClick={() =>
                                            openModal(option, index)
                                          }
                                        >
                                          <p
                                            style={{
                                              margin: "0 2rem",
                                              color: "#ffffff",
                                            }}
                                          >
                                            Details
                                          </p>
                                        </button>
                                        &nbsp; &nbsp;
                                        <Link
                                          to={`/edit_landmark/${option.primary_id}`}
                                        >
                                          <button
                                            style={{
                                              border: "none",
                                              background: "transparent",
                                            }}
                                          >
                                            <img
                                              className=""
                                              src={update}
                                              alt="update"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </button>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    key={`spacer-${index}`}
                                    style={{ height: "1rem" }}
                                  ></tr>
                                </React.Fragment>
                              ))}
                              {filteredData.length === 0 && (
                                <>
                                  <tr>
                                    <td
                                      colSpan={6}
                                      className="text_align_center"
                                    >
                                      No Results Found
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img bodyText">
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Addresh :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.landmark_name}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Map Addresh :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.landmark_map_name}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Lat :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.lat}</p>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-5">
                  <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                    {"Lng :"}
                  </p>
                </div>
                <div className="col-7">
                  <p>{SelectedData.lng}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="logoutNoBtn" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModal1}
          onHide={closeModal1}
          centered
          backdrop="static"
        >
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              {/* ... Modal content goes here ... */}
            </div>

            <p>
              Are you sure you want to mark{" "}
              <span style={{ color: "#e33745" }}>{clickedButton}</span>
              guest?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex)}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal1}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default LandMarkView;
