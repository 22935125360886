import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import "./Css/Onboading.css";
import EmployeeImg from "../assets/storeNew1.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Editred from "../assets/editred.svg";
import { FaPen, FaEye } from "react-icons/fa";
import GreyArrow from "../assets/greyLeftAr.png";
import { Modal, Button } from "react-bootstrap";
import installIcon from "../assets/installIcon.svg";
import {
  server_post_data,
  get_all_new_resturant_onboarding,
  APL_LINK,
  new_restaurant_install_btn,
  get_subs_data,
} from "../ServiceConnection/serviceconnection.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  computeTodayDate,
  handleAphabetsChange,
  handleError,
  handleEmailChange,
  handleNumbersChange,
  handleIaphabetnumberChange,
  calculateMaxDate,
  computeplussevendays,
  handleSuccess,
  handleSuccessSession
} from "../CommonJquery/CommonJquery.js";
import {
  ViewStaffPagesText,
  options_search,
  GuestManagementPageText,
} from "./../CommonJquery/WebsiteText";
import { storeData } from "../LocalConnection/LocalConnection.js";

function OnboadingSideTab() {
  const navigate = useNavigate();
  let flag = "1";
  let formattedSevenDaysAgo = computeplussevendays();
  let formattedToday = computeTodayDate();

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedValue2, setSelectedValue2] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [selectedValue, setSelectedValue] = useState("Name");
  const [searchfilter, setSearchFilter] = useState("");
  const [SelectedData, setSelectedData] = useState([]);
  const [SelectedData1, setSelectedData1] = useState([]);
  const [subsdata, setSubsData] = useState([])
  const [ProductOptions, setProductOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [restaurantid, setrestaurantid] = useState('');
  const [subsDay, setsubsDay] = useState('');
  useEffect(() => {
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);


  useEffect(() => {
    if (subsDay !== '') {
      const currentDate = new Date(); 
      if (!isNaN(currentDate.getTime())) {
        currentDate.setDate(currentDate.getDate() + parseInt(subsDay, 10));
        const updatedDate = currentDate.toISOString().split("T")[0];
        setSelectedDate(updatedDate); 
      }
    }
  }, [subsDay]);

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };
  const [showModal, setShowModal] = useState(false);
  const openModal = (guestData) => {
    setrestaurantid(guestData.primary_id)
    get_subs_data_api(guestData.primary_id)
    setSelectedData1(guestData);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };


  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_new_resturant_onboarding, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_restaurant.forEach((subObj) => {
            if (subObj.active_status === 1) {
              subObj.activedata = "Active";
            } else {
              subObj.activedata = "Deactive";
            }
          });
          seteditStaffData(Response.data.message.data_restaurant);
          setfilteredData(Response.data.message.data_restaurant);
          setProductOptions(Response.data.message.data_restaurant_options);
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_restaurant_image
          );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };



  const get_subs_data_api = async (
    call_id,
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id)
    fd.append("call_id", call_id);
    await server_post_data(get_subs_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSubsData(Response.data.message)
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const InstallRestaurant = async (
    call_id,
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", restaurantid);
    fd.append("sub_day", subsDay);
    fd.append("date_data", selectedDate);
    fd.append("sub_id", call_id);
    await server_post_data(new_restaurant_install_btn, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
          setshowLoaderAdmin(false);
        } else {
          closeModal()
          setshowLoaderAdmin(false);
          handleSuccessSession(Response.data.message,'/View_Restaurants');
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleSelect2 = (event) => {
    setSelectedValue2(event.target.value);
  };
  const search_data = () => {
    flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };

  const [showModalStep, setShowModalStep] = useState(false);

  const handleEdit = (option) => {
    storeData("staff_id", option.owner_staff_id);
    storeData("default_restaurant_id_staff", option.primary_id);
    navigate(
      "/Edit_restaurant_new/" + option.primary_id + "/" + option.owner_staff_id
    );
  };
  const handleViewStepModal = () => {
    setShowModalStep(!showModalStep);
  };
  const handleCloseModal = () => {
    setShowModalStep(false);
  };

  const handleChange1 = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };
  const openCustomDatePicker = (customDatePickerInput_id) => {
    document.getElementById(customDatePickerInput_id).focus();
  };

  return (
    <div className="reservationHistory">
      {showLoaderAdmin && <Loader />}
      <Header />
      <div className="pageCntn_head">
        <div className="pageNameDiv">
          <p>{GuestManagementPageText.Restaurant_Management}</p>
          <img src={GreyArrow} alt="Dfoodo Dashboard" />
        </div>
        <Link to="/add_restaurant_new">
          {/* <button
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Add Restaurant"
            className="createDiningArea createRestaurant Add_Restaurant"
          >
            Add Restraurant
          </button> */}
        </Link>
      </div>
      <div className="viewStaff_head">
        <div className="row m-0">
          <div className="col-lg-2 col-sm-4 bottomAlgin">
            <label className="labelView">{ViewStaffPagesText.Start_Date}</label>
            <div className="person__calenderFrame_image image_icon_position1 ">
              <input
                id="startDate"
                type="date"
                placeholder={ViewStaffPagesText.D_O_B}
                className="form-control  input_field_custom4"
                defaultValue={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={computeTodayDate()}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 bottomAlgin">
            <label className="labelView">{ViewStaffPagesText.End_Date}</label>
            <div className="person__calenderFrame_image image_icon_position1 ">
              <input
                id="endDate"
                type="date"
                placeholder={ViewStaffPagesText.D_O_B}
                className="form-control  input_field_custom4"
                defaultValue={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                max={computeTodayDate()}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-4 bottomAlgin">
            <div className="inputDiv2">
              <label className="labelPointer" htmlFor="selectFilter">
                <img src={FilterIcon} alt="Dfoodo Dashboard" />
              </label>
              <select id="selectFilter" onChange={handleSelect}>
                {options_search.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-5 col-sm-8 bottomAlgin">
            <div className="inputDiv2">
              <img src={Search} alt="Dfoodo Dashboard" />
              <input
                type="text"
                id="search_data"
                onInput={(e) => {
                  if (selectedValue === options_search[0].value) {
                    handleAphabetsChange(e);
                  } else if (selectedValue === options_search[1].value) {
                    handleEmailChange(e);
                  } else if (selectedValue === options_search[2].value) {
                    handleNumbersChange(e);
                  }
                }}
                placeholder={ViewStaffPagesText.Search_Employee_reservation}
              />
              <button
                type="button"
                className="btnSearch"
                onClick={() => search_data()}
              >
                {ViewStaffPagesText.Search_text}
              </button>
            </div>
          </div>

          <div
            className="col-md-4 centerAlgin"
            style={{ marginLeft: "auto", marginRight: "0" }}
          >
            <div className="inputDiv2">
              <label className="labelPointer" htmlFor="selectFilter">
                <img src={FilterIcon} alt="Dfoodo Dashboard" />
              </label>
              <input
                type="text"
                placeholder={ViewStaffPagesText.Staff_Filter_reservation}
                onInput={handleIaphabetnumberChange}
                value={searchfilter}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="viewGuest_table ">
        <div className="viewGuest_table_container ">
          <div className="row m-0">
            <div className="col-md-12 table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Restaurant Details
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingRight: "1rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Contact Details
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "0.3rem",
                      }}
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "0.5rem",
                        paddingRight: "1rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Subscription Date
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      Onboarding
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((option, index) => (
                    <React.Fragment key={index}>
                      <tr className="tableRow tbodyStyle">
                        <td className="th1">
                          <div className="rsvnDetails saparator1">
                            <div className="rsvnDetailsImg">
                              <img
                                src={
                                  StaffImageLinkData + option.restaurant_image
                                }
                                onError={(e) => (e.target.src = EmployeeImg)}
                                alt="Dfoodo Dashboard"
                              />
                            </div>
                            <div className="rsvnDetailsText">
                              <h6>{option.restaurant_name}</h6>
                              <p>{option.restaurant_tagline}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="rsvnDetails">
                            <div className="rsvnContactDetails">
                              <p>{option.restaurant_mobile_no}</p>
                              {option.restaurant_other_no &&
                                option.restaurant_other_no.length > 2 && (
                                  <p>{option.restaurant_other_no}</p>
                                )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="rsvnDetails">
                            <div className="rsvnContactDetails">
                              <p>{option.restaurant_email}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="rsvnDetails">
                            <div className="rsvnContactDetails">
                              <p>{option.subscription_start_date}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="rsvnDetails">
                            <div className="rsvnContactDetails Actionbttns">
                              {option.restaurant_step_save === 13 ? (
                                option.restaurant_launch_status === 0 ? (
                                  <button onClick={() => openModal(option)}>
                                    <img
                                      src={installIcon}
                                      alt="Install"
                                      title="Install"
                                    ></img>
                                  </button>
                                ) : "Completed"

                              ) : (
                                <p>Step {option.restaurant_step_save}</p>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className="th2 tabledata">
                          <div className="actionsAlign d-flex justify-content-center marginClssOnbo">
                            {/* <button
                              type="button"
                              onClick={handleViewStepModal}
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                marginRight: "10px",
                              }}
                            >
                              <FaEye
                                size={15}
                                color="var(--light-text)"
                                title="Complated Step"
                              />
                            </button> */}
                            <button
                              type="button"
                              onClick={() => handleEdit(option)}
                              className="closeModalStep"
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                              }}
                            >
                              <FaPen
                                size={15}
                                color="var(--primary-color)"
                                title="Edit"
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr style={{ height: "1rem" }}></tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        show={showModalStep}
        onHide={handleCloseModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body" style={{ position: "relative" }}>
          <div className="closeStepModl" onClick={handleCloseModal}>
            <IoClose className="closeIcnModlStep" size={32} />
          </div>
          <div className="w-100 success_img d-flex justify-content-center">
            <p>Step-1</p>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body justify-content-start">
          <div className="success_img bodyText">

            <div className="row m-0">
              <div className="col-5">
                <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                  {"Name :"}
                </p>
              </div>
              <div className="col-7">
                <p>{subsdata.restaurant_name}</p>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-5">
                <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                  {"Transaction Id :"}
                </p>
              </div>
              <div className="col-7">
                <p>{subsdata.transaction_id}</p>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-5">
                <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                  {"Subscription Amount :"}
                </p>
              </div>
              <div className="col-7">
                <p>{subsdata.subscription_amount}</p>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-5">
                <p style={{ fontWeight: "400", letterSpacing: "0" }}>
                  {"Subscription Date:"}
                </p>
              </div>
              <div className="col-7">
                <p>{subsdata.subscription_start_date}</p>
              </div>
            </div>

            <form id="AddSubsData">

              <div className="form-group">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="subscription_period"
                >
                  Subscription Period (In Day's)
                </label>
                <input
                  style={{ marginBottom: "0.5rem" }}
                  type="text"
                  onInput={handleNumbersChange}
                  className="form-control"
                  id="subscription_period"
                  name="subscription_period"
                  defaultValue={subsDay}
                  onChange={(e) => setsubsDay(e.target.value)}
                  placeholder="No of Days"
                  maxLength={4}

                />
              </div>
              <div className="form-group">
                <label style={{ fontWeight: "500" }} htmlFor="subscription_period">
                  Subscription End Date
                </label>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  className=" trio_mandatory form-control  input_field_custom1  "
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select a date"
                />
              </div>

            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="logoutNoBtn hoverRed" onClick={closeModal}>
            Close
          </Button>
          <Button className=" hoverRed" onClick={()=>InstallRestaurant(subsdata.primary_id)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OnboadingSideTab;
