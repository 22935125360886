import React, { useState, useEffect } from "react";

import axios from "axios";
import backButton from "../assets/backButton.svg";
import "./Css/Newonboading.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import { Country, State } from "country-state-city";
import { retrieveData } from "../LocalConnection/LocalConnection";

import {
  handleError,
  validateEmail,
  validateMobileplus,
  handleEmailChange,
} from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
  sava_onboarding_form_otp,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep1 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [states, setStates] = useState([]);

  const [showButton, setShowButton] = useState(true); // State to control button visibility
  const [countdown, setCountdown] = useState(60);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [address, setAddress] = useState({
    email: "",
    location: "",
    street: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    contactNumber: "",
    OTP_STATUS: false,
    OTP_ENTER: false,
    OTP_ERORR: false,
    OTP_value: "",
    OTP_come: "",
    countryCode: "+91",
  });

  const [errors, setErrors] = useState({});
  const [shake, setShake] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [restaurantData, setrestaurantData] = useState([]);

  const [countries, setCountries] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    let value_ddd = value;
    if (name === "countryCode") {
      value_ddd = validateMobileplus(value);
    }
    setAddress((prev) => ({ ...prev, [name]: value_ddd }));
    if (value_ddd.trim() !== "") {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };
  const handlezipcodeChange = async (e) => {
    const zipCode = e.target.value;
    console.log("Entered pincode:", zipCode);

    // Update ZIP/PIN code immediately
    setAddress((prevAddress) => ({
      ...prevAddress,
      zip: zipCode,
    }));

    // Only proceed if pincode is 6 digits (Indian PIN codes are 6 digits)
    if (zipCode.length !== 6 || !/^\d+$/.test(zipCode)) {
      console.log("Please enter a valid 6-digit PIN code");
      return;
    }

    try {
      console.log("Fetching location data for PIN code:", zipCode);

      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${zipCode}`
      );

      if (response.data && response.data[0].Status === "Success") {
        const postOffice = response.data[0].PostOffice[0];
        const city = postOffice.District || "Unknown City";
        const state = postOffice.State || "Unknown State";
        const country = "India";

        console.log("Location Details:");
        console.log("----------------");
        console.log("PIN Code:", zipCode);
        console.log("City:", city);
        console.log("State:", state);
        console.log("Country:", country);

        setAddress((prevAddress) => ({
          ...prevAddress,
          country: country,
          state: state,
          city: city,
        }));
      } else {
        console.log("Invalid PIN code or no location found");
        setAddress((prevAddress) => ({
          ...prevAddress,
          city: "",
          state: "",
          country: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching location data:", error.message);

      // Show specific error messages based on error type
      if (error.response) {
        switch (error.response.status) {
          case 404:
            console.log("PIN code not found");
            break;
          case 429:
            console.log("Too many requests. Please try again later");
            break;
          default:
            console.log("Server error. Please try again");
        }
      } else if (error.request) {
        console.log("No response received. Check your internet connection");
      } else {
        console.log("Error in request setup");
      }

      setAddress((prevAddress) => ({
        ...prevAddress,
        city: "",
        state: "",
        country: "",
      }));
    }
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const handleNextStep = () => {
    const newErrors = {};
    let hasError = false;
    // console.log(address);
    // Object.keys(address).forEach((field) => {
    //   try {
    //     if (field !== "OTP_STATUS" && field !== "OTP_ENTER") {
    //       if (address[field] === null || address[field] === "") {
    //         newErrors[field] = true;
    //         hasError = true;
    //       }
    //     }
    //   } catch (err) {
    //     newErrors[field] = true;
    //     hasError = true;
    //   }
    // });

    // if (address["OTP_STATUS"] === false) {
    //   newErrors["contactNumber_OTP"] = true;
    //   hasError = true;
    // } else {
    //   newErrors["contactNumber_OTP"] = false;
    // }

    // if (address["OTP_STATUS"] === true) {
    //   if (parseInt(address["OTP_value"]) !== parseInt(address["OTP_come"])) {
    //     newErrors["OTP_ERORRRRRR"] = true;
    //     hasError = true;
    //   } else {
    //     newErrors["OTP_ERORRRRRR"] = false;
    //   }
    // }

    if (hasError) {
      setErrors(newErrors);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } else {
      setErrors(newErrors);
      handleSaveChangesdynamic();
    }
  };

  const handleOTPverify = () => {
    const newErrors = {};
    let hasError = false;

    if (
      address["contactNumber"] === null ||
      address["contactNumber"].trim() === "" ||
      address["contactNumber"].length < 9
    ) {
      newErrors["contactNumber"] = true;
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } else {
      takeotpfromserver();
      startCountdown();
    }
  };

  const startCountdown = () => {
    setShowButton(false); // Hide the button
    setCountdown(60); // Reset countdown to 60 seconds

    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setShowButton(true); // Show the button again after countdown
          return 0; // Reset countdown to 0
        }
        return prev - 1; // Decrement countdown
      });
    }, 1000); // Update countdown every second
  };

  // Format countdown to MM:SS
  const formatCountdown = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_full_adrress", address.location);
    form_data.append("restaurant_temorary_adrress", address.street);
    form_data.append("restaurant_city", address.city);
    form_data.append("restaurant_state", address.state);
    form_data.append("restaurant_country", address.country);
    form_data.append("restaurant_zipcode", address.zip);
    form_data.append("restaurant_email", address.email);
    form_data.append("restaurant_mobile_no", address.contactNumber);
    form_data.append("restaurant_country_code", address.countryCode);
    form_data.append("step_save", "1");

    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  const takeotpfromserver = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_mobile_no", address.contactNumber);
    form_data.append("restaurant_country_code", address.countryCode);

    await server_post_data(sava_onboarding_form_otp, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setAddress((prev) => ({
            ...prev,
            ["OTP_come"]: Response.data.message.staff_otp,
            ["OTP_ENTER"]: true,
            ["OTP_STATUS"]: true,
            ["OTP_ERORR"]: false,
          }));
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setAddress({
              location:
                Response.data.message.restaurant_data[0]
                  .restaurant_full_adrress,
              street:
                Response.data.message.restaurant_data[0]
                  .restaurant_temorary_adrress,
              country:
                Response.data.message.restaurant_data[0].restaurant_country,
              city: Response.data.message.restaurant_data[0].restaurant_city,
              state: Response.data.message.restaurant_data[0].restaurant_state,
              zip: Response.data.message.restaurant_data[0].restaurant_zipcode,
              contactNumber:
                Response.data.message.restaurant_data[0].restaurant_mobile_no,
              countryCode:
                Response.data.message.restaurant_data[0]
                  .restaurant_country_code !== null
                  ? Response.data.message.restaurant_data[0]
                      .restaurant_country_code
                  : "+91",
              OTP_come: "",
              OTP_ENTER: false,
              OTP_STATUS: false,
              OTP_ERORR: false,
            });
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Get all countries on component mount
    const allCountries = Country.getAllCountries();
    setCountries(
      allCountries.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const {
      email,
      location,
      street,
      country,
      city,
      state,
      zip,
      contactNumber,
    } = address;
    // Check if the necessary fields are filled
    const isFilled =
      email &&
      location &&
      street &&
      country &&
      city &&
      state &&
      zip &&
      contactNumber;
    setIsFormValid(isFilled);
  }, [address]);

  const handleCountryChange = (selectedCountry) => {
    const countryValue = selectedCountry.label;
    const countryValue2 = selectedCountry.value;
    setAddress((prevAddress) => ({
      ...prevAddress,
      country: countryValue,
      state: "", // Reset state when country changes
    }));

    // Get states for the selected country
    const allStates = State.getStatesOfCountry(countryValue2);
    setStates(
      allStates.map((state) => ({
        value: state.isoCode,
        label: state.name,
      }))
    );
  };

  const handleStateChange = (selectedState) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      state: selectedState ? selectedState.label : "",
    }));
  };
  const handleMobilevalidation = (e) => {
    const { name, value } = e.target;

    if (name === "contactNumber" && !/^\d*$/.test(value)) {
      return;
    }
    if (name === "OTP_ENTER" && !/^\d*$/.test(value)) {
      return;
    }

    handleChange(e);
  };

  const handleEmailValidation = (e) => {
    const { name, value } = e.target;

    setAddress((prev) => ({ ...prev, [name]: value }));

    // Clear error if input is not empty
    if (value.trim() === "") {
      setErrors((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));

    // Validate email
    if (value.trim() === "") {
      setErrors((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  return (
    <div className="containerRight gap-0">
      <div className="rightHeading">
        <div className="d-flex BackHeading">
          <button onClick={prevStep}>
            <img src={backButton} alt="backButton" />
          </button>
          <h3>Enter your restaurant address</h3>
        </div>
        <p>Add a location where customers can visit you</p>
      </div>
      <div className="overflowHeightstep1">
        <div style={{ overflow: "scroll", height: "100vh" }}>
          <div className="col-sm-9 mb-3">
            <div className="row">
              <div className="col-3" style={{ position: "relative" }}>
                <input
                  type="text"
                  name="countryCode"
                  placeholder="+91"
                  maxLength={5}
                  value={address.countryCode}
                  className={`form-control ${
                    errors.countryCode ? "input-error" : ""
                  } ${shake ? "shake" : ""} ${
                    focusedField === "countryCode" ? "input-focus" : ""
                  }`}
                  onFocus={() => handleFocus("countryCode")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.countryCode && (
                  <span className="error-message">
                    Country code is required
                  </span>
                )}
              </div>
              <div
                className="col-9"
                style={{ position: "relative", paddingLeft: "0" }}
              >
                {(focusedField === "contactNumber" ||
                  address.contactNumber) && (
                  <label className="HeadBussiness">Phone Number*</label>
                )}
                <div
                  className={`mobileCOntnt ${
                    focusedField === "contactNumber" ? "input-focus" : ""
                  }`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="text"
                    minLength={10}
                    maxLength={10}
                    value={address.contactNumber}
                    name="contactNumber"
                    placeholder="Phone Number*"
                    className={`form-control ActiveFormCOntrol ${
                      errors.contactNumber ? "input-error" : ""
                    } ${shake ? "shake" : ""}`}
                    style={{ flex: 1 }}
                    onFocus={() => handleFocus("contactNumber")}
                    onBlur={handleBlur}
                    onChange={handleMobilevalidation}
                  />

                  <button
                    type="button"
                    style={{ display: showButton ? "flex" : "none" }}
                    onClick={() => handleOTPverify()}
                  >
                    Get OTP
                  </button>
                  {!showButton && (
                    <button type="button" style={{ cursor: "text" }}>
                      {formatCountdown(countdown)}
                    </button>
                  )}
                </div>

                {errors.contactNumber && (
                  <span className="error-message">
                    Phone number is required
                  </span>
                )}
                {errors.contactNumber_OTP && (
                  <span className="error-message">
                    Verification is required
                  </span>
                )}
              </div>
            </div>
          </div>

          {address.OTP_ENTER && (
            <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
              {focusedField === "OTP_value" && (
                <label className="HeadBussiness">OTP Number*</label>
              )}
              <div
                className={`mobileCOntnt ${
                  focusedField === "OTP_value" ? "input-focus" : ""
                }`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="text"
                  minLength={6}
                  maxLength={6}
                  value={address.OTP_value}
                  name="OTP_value"
                  placeholder="OTP Number*"
                  className={`form-control ActiveFormCOntrol ${
                    errors.OTP_value ? "input-error" : ""
                  } ${shake ? "shake" : ""}`}
                  style={{ flex: 1 }}
                  onFocus={() => handleFocus("OTP_value")}
                  onBlur={handleBlur}
                  onChange={handleMobilevalidation}
                />
              </div>
              {errors.OTP_ERORRRRRR && (
                <span className="error-message">Correct OTP is required</span>
              )}
            </div>
          )}

          <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
            {(focusedField === "email" || address.email) && (
              <label className="HeadBussiness">Restaurant Email ID*</label>
            )}

            <input
              type="text"
              name="email"
              placeholder="Restaurant Email ID*"
              className={`form-control ${errors.email ? "input-error" : ""} ${
                shake ? "shake" : ""
              } ${focusedField === "email" ? "input-focused" : ""}`}
              value={address.email}
              onFocus={() => handleFocus("email")}
              onBlur={handleBlur}
              onChange={handleEmailChange}
            />
            {errors.email && (
              <span className="error-message">Email is required</span>
            )}
          </div>
          <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
            {(focusedField === "location" || address.location) && (
              <label className="HeadBussiness">Location Address*</label>
            )}
            <div className="mobileCOntnt">
              <input
                type="text"
                placeholder="Restaurant Location"
                minLength={5}
                maxLength={100}
                name="location"
                className={`form-control  ${
                  errors.location ? "input-error" : ""
                } ${shake ? "shake" : ""} ${
                  focusedField === "location" ? "input-focused" : ""
                }`}
                value={
                  address.location &&
                  address.location
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")
                }
                onFocus={() => handleFocus("location")}
                onBlur={handleBlur}
                onChange={(e) => handleEmailValidation(e, "location")}
              />
            </div>
            {errors.location && (
              <span className="error-message">Location is required</span>
            )}
          </div>

          <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
            {(focusedField === "street" || address.street) && (
              <label className="HeadBussiness">Street Address*</label>
            )}
            <input
              type="text"
              name="street"
              placeholder="Street Address"
              minLength={10}
              maxLength={150}
              className={`form-control ${errors.street ? "input-error" : ""} ${
                shake ? "shake" : ""
              } ${focusedField === "street" ? "input-focused" : ""}`}
              value={
                address.street &&
                address.street
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")
              }
              onFocus={() => handleFocus("street")}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.street && (
              <span className="error-message">Street address is required</span>
            )}
          </div>

          <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
            {(focusedField === "country" || address.country) && (
              <label className="HeadBussiness">Country*</label>
            )}
            <Select
              name="Country"
              options={countries}
              className={`form-control CountryNameE ${
                errors.country ? "input-error" : ""
              }`}
              placeholder="Country"
              value={
                address.country
                  ? {
                      value: address.country,
                      label: countries.find((c) => c.label === address.country)
                        ?.label,
                    }
                  : null
              }
              onChange={handleCountryChange}
            />
            {errors.country && (
              <span className="error-message">Country is required</span>
            )}
          </div>

          <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
            {(focusedField === "city" || address.city) && (
              <label className="HeadBussiness">City*</label>
            )}
            <input
              type="text"
              name="city"
              placeholder="City"
              minLength={3}
              maxLength={85}
              className={`form-control ${errors.city ? "input-error" : ""} ${
                shake ? "shake" : ""
              } ${focusedField === "city" ? "input-focused" : ""}`}
              value={address.city}
              onFocus={() => handleFocus("city")}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.city && (
              <span className="error-message">City is required</span>
            )}
          </div>

          <div className="col-sm-9 mb-3" style={{ position: "relative" }}>
            <div className="row after400Col">
              <div
                className="col-6 mb-3 stateDiv"
                style={{
                  position: "relative",
                }}
              >
                {(focusedField === "state" || address.state) && (
                  <label className="HeadBussiness2">State*</label>
                )}
                <Select
                  name="state"
                  options={states}
                  className={`form-control CountryNameE ${
                    errors.state ? "input-error" : ""
                  }`}
                  value={
                    address.state
                      ? {
                          value: address.state,
                          label: states.find((s) => s.label === address.state)
                            ?.label,
                        }
                      : null
                  }
                  onChange={handleStateChange}
                  placeholder={address.country ? "State" : "State"}
                  isDisabled={!address.country}
                />
                {errors.state && (
                  <span className="error-message">State is required</span>
                )}
              </div>

              <div
                className="col-6 zipcodeDiv"
                style={{ position: "relative" }}
              >
                {(focusedField === "zip" || address.zip) && (
                  <label className="HeadBussiness2">Zip Code*</label>
                )}
                <input
                  type="text"
                  name="zip"
                  placeholder="Zip Code"
                  className={`form-control ${errors.zip ? "input-error" : ""} ${
                    shake ? "shake" : ""
                  } ${focusedField === "zip" ? "input-focused" : ""}`}
                  value={address.zip}
                  onFocus={() => handleFocus("zip")}
                  onBlur={handleBlur}
                  minLength={4}
                  maxLength={10}
                  onChange={handlezipcodeChange}
                />
                {errors.zip && (
                  <span className="error-message">Zip code is required</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`newClass2 fixNextButton ${isFormValid ? "newClass" : ""}`}
          style={{ marginTop: "0px" }}
        >
          <div className="container-lg d-flex justify-content-end">
            <button
              onClick={handleNextStep}
              style={{
                opacity: isFormValid ? 1 : 0.5,
                // pointerEvents: isFormValid ? "auto" : "none",
              }}
              // disabled={!isFormValid}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep1;
