import React, { useState, useEffect } from "react";
import backButton from "../assets/backButton.svg";
import dragPhoto from "../assets/dragPhoto.svg";
import dltIcn from "../assets/dltIcn.svg";
import restauimgexp from "../assets/restauimgexp.png";
import bannimg from "../assets/bannimg.png";
import menu from "../assets/menu.png";
import Swal from "sweetalert2";
import "./Css/Newonboading.css";
import tooltip from "../assets/tooltip.svg";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { combiled_form_data, handleError } from "../CommonJquery/CommonJquery";
import { Modal, Button } from "react-bootstrap";

import { useDropzone } from "react-dropzone";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
  delete_resturant_images_menu,
  APL_LINK,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep11 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [files, setFiles] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedImages, SetselectedImages] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsguploadimg, seterrorMsguploadimg] = useState("");
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleNext = () => {
    if (selectedImages.length > 0) {
      handleSaveChangesdynamic();
    } else if (files.length < 1) {
      seterrorMsguploadimg("Please upload an image!");
    } else {
      handleSaveChangesdynamic();
    }
  };

  const handleDropFiles = (acceptedFiles) => {
    const fileCount = files.length;
    const newFiles = acceptedFiles
      .filter((file) => {
        if (file.size > 500 * 1024) {
          setErrorMsg("Please upload an image under 500KB!");
          return false;
        }
        return true;
      })
      .map((file, index) => {
        const keyname = `file_${fileCount + index}`;
        const preview = file.type.startsWith("image/")
          ? URL.createObjectURL(file)
          : null;

        return {
          file,
          keyname,
          preview,
        };
      });

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    if (newFiles.length > 0) {
      setErrorMsg("");
    }
  };
  // Handlers for the first upload section
  const { getRootProps: getFirstRootProps, getInputProps: getFirstInputProps } =
    useDropzone({
      multiple: true,
      accept: "image/*",
      onDrop: handleDropFiles,
    });

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    files.forEach(({ file, keyname }) => {
      form_data.append("images_loop_menu", file, file.name); // Include original filename
    });
    form_data.append("step_save", "11");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };
  const removeImage = (index, from_where) => {
    if (from_where === 0) {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
    } else {
      handleShow(index);
    }
  };

  const master_data_action_update = async (call_id, image_name) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("image_id", call_id);
    fd.append("image_name", image_name);
    fd.append("flag_key", "1");
    await server_post_data(delete_resturant_images_menu, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          master_data_get();
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const handleDeactivate = () => {
    master_data_action_update(SelectedData.primary_id, SelectedData.image_name);
  };
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            SetselectedImages(Response.data.message.menu_images);
            setsStaffImageLinkData(APL_LINK + Response.data.message.image);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>

            <div className="tooltipImg seattooltip border-0">
              <h3>Upload Menu </h3>
              <img src={tooltip} alt="tooltip" />
              <div className="tooltiptext">
                If you have an image of the restaurant that is less than 5 MB,
                you can upload it here using the file upload feature. Once you
                do that, I'll help you with any content-related tasks you need.
              </div>
            </div>
          </div>
          <p style={{ margin: "0px" }}>
            Upload Menu Image who will Display on D Foodo Website
          </p>
          {errorMsg && <label className="MaxSizeLAbel">{errorMsg}</label>}

          {errorMsguploadimg && (
            <label className="MaxSizeLAbel">{errorMsguploadimg}</label>
          )}
        </div>
        <div className="overflowHeight3">
          <div className=" w-100 row " style={{ flexWrap: "wrap" }}>
            <div className="col-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  onDragOver={handleDragOver}
                  {...getFirstRootProps({
                    className:
                      "dropzone image-preview dragePhotoCont thumb mrginCLass",
                    style: { width: "100%", maxWidth: "100%" },
                  })}
                >
                  <p>Drag photo here</p>
                  <p>or</p>
                  <div className="photoImg">
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      {...getFirstInputProps({ multiple: true })}
                      id="file-upload"
                      name="files_finals[]"
                    />
                    <img src={dragPhoto} alt="Drag Photo" />

                    <button className="upload-button">Select Photo</button>
                  </div>
                </div>

                <span></span>
              </div>
            </div>
          </div>
          <div className="containerMenuImgs">
            {files && files.length > 0 && (
              <div className=" image_preview w-100">
                {files.map((file, index) => (
                  <div key={index} className="imgCont thumb">
                    <img
                      src={file.preview}
                      alt={file.name}
                      className="thumb-img"
                    />
                    <img
                      src={dltIcn}
                      alt="Remove"
                      className="deleteImgicn"
                      onClick={() => removeImage(index, 0)}
                    />
                  </div>
                ))}
              </div>
            )}

            {selectedImages && selectedImages.length > 0 && (
              <div className="image_preview">
                {selectedImages.map((option, index) => (
                  <div key={index} className="imgCont thumb">
                    <img
                      src={StaffImageLinkData + option.image_name}
                      alt={option.image_name}
                      className="thumb-img img-fluid shadow"
                    />
                    <img
                      src={dltIcn}
                      alt="Remove"
                      className="deleteImgicn"
                      onClick={() => removeImage(option, 1)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="footerbtttn fixNextButton">
            <div className="container-lg d-flex justify-content-end gap-3">
              {/* <div className="skipbutton" onClick={handleNext}>
            <button>Skip</button>
          </div> */}
              <div className="NextButton">
                <button onClick={handleNext} style={{ margin: 0 }}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Delete This Data?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleDeactivate}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Onboadingstep11;
