import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Components/Login";
import ForgotPassword from "./Components/ForgotPassword";
import ChangePassword from "./Components/ChangePassword";
import AddStaff from "./Components/AddStaff";
import ViewStaff from "./Components/ViewStaff";
import GuestMngmt from "./Components/GuestMngmt";
import SubscriptionManagement from "./Components/SubscriptionManagement";
import CreateStaffRights from "./Components/CreateStaffRights";
import ViewStaffRights from "./Components/ViewStaffRights";
import CreateSubStaffRights from "./Components/CreateSubStaffRights";
import ViewSubRights from "./Components/ViewSubRights";
import EditSubRights from "./Components/EditSubRights";
import InsidePageRightsView from "./Components/InsidePageRightsView";
import CreateInsidePageRights from "./Components/CreateInsidePageRights";
import ProfileSettings from "./Components/ProfileSettings";
import SystemSettings from "./Components/SystemSettings";
import Dashboard from "./Components/Dashboard";
import RestaurantSetup from "./Components/RestaurantSetup";
import CreateRestro from "./Components/CreateRestro";
import AddDesignation from "./Components/AddDesignation";
import ViewDesignation from "./Components/ViewDesignation";
import ViewSearchMaster from "./Components/ViewSearchMaster";
import Add_SearchMaster from "./Components/Add_SearchMaster";
import Manuals from "./Components/Manuals";
import Add_Faqs from "./Components/Add_Faqs";
import ViewFaq from "./Components/ViewFaq";
import ViewKB from "./Components/ViewKB";
import AddKB from "./Components/AddKB";
import Report from "./Components/Reports";
import WebsiteMngt from "./Components/WebsiteMngmt";
import RestaurantEnquiry from "./Components/RestaurantEnquiry";
import RestaurantReviews from "./Components/RestaurantReviews";
import CuisinesView from "./Components/CuisinesView";
import CuisinesAdd from "./Components/CuisinesAdd";
import CollectionsView from "./Components/CollectionsView";
import CollectionsAdd from "./Components/CollectionsAdd";
import AmenitiesView from "./Components/AmenitiesView";
import AmenitiesAdd from "./Components/AmenitiesAdd";
import CityView from "./Components/CityView";
import CityAdd from "./Components/CityAdd";
import CountryView from "./Components/CountryView";
import Help from "./Components/Help";
import CreateNewTicket from "./Components/CreateTicket";
import ReplyToTicket from "./Components/ReplyToTickect";
import ReservationHistory from "./Components/ReservationHistory";
import CreateRestroMenu from "./Components/CreateRestroMenu";
import CreateRestroImages from "./Components/CreateRestroImages";
import Analytics from "./Components/Analytics";
import { ToastContainer } from "react-toastify";
import "./Components/Css/AddStaff.css";
import "./Components/Css/Analytics.css";
import "./Components/Css/Calender.css";
import "./Components/Css/Dashboard.css";
import "./Components/Css/Header.css";
import "./Components/Css/HelpAndSupport.css";
import "./Components/Css/HelpSupport.css";
import "./Components/Css/Loading.css";
import "./Components/Css/Login.css";
import "./Components/Css/Reports.css";
import "./Components/Css/Reservations.css";
import "./Components/Css/RestoSetup.css";
import "./Components/Css/Settings.css";
import "./Components/Css/SideBar.css";
import "./Components/Css/StaffRights.css";
import "./Components/Css/TableSetup.css";
import "./Components/Css/WebsiteMngmt.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import LandMarkView from "./Components/LandMarkView";
import LandMarkAdd from "./Components/LandMarkAdd";
import NotificationMngmt from "./Components/NotificationMngmt";
import AddRestaurantNew from "./Components/AddRestaurantNew";
import OnboadingSideTab from "./Components/OnboadingSideTab";
function App() {
  useEffect(() => {
    console.log("Effect is running");
    const inputElements = document.getElementsByTagName("input");
    Array.from(inputElements).forEach((input) => {
      input.setAttribute("autocomplete", "off");
    });
  }, [Array]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Working Screen */}

          {/* Login Screens */}
          <Route path="/" element={<Login />} />
          <Route path="/Forgot_Password" element={<ForgotPassword />} />
          <Route path="/Change_Password/:id" element={<ChangePassword />} />

          {/* Add and Edit Staff Pages */}
          <Route path="/View_Staff" element={<ViewStaff />} />
          <Route path="/Add_Staff" element={<AddStaff />} />
          <Route path="/edit_staff/:id" element={<AddStaff />} />

          {/* Profile Settings Pages */}
          <Route path="/Profile_Settings" element={<ProfileSettings />} />

          {/* Guest Management Pages */}
          <Route path="/View_Guest" element={<GuestMngmt />} />
          {/* Subscription Management Pages */}
          <Route
            path="/View_Subsciption"
            element={<SubscriptionManagement />}
          />

          {/* Restraurant Setup Pages */}
          <Route path="/View_Restaurants" element={<RestaurantSetup />} />
          <Route path="/Create_Restaurant" element={<CreateRestro />} />
          <Route path="/edit_restaurants/:id" element={<CreateRestro />} />
          <Route
            path="/edit_restaurants_menu/:id"
            element={<CreateRestroMenu />}
          />
          <Route
            path="/edit_restaurants_images/:id"
            element={<CreateRestroImages />}
          />
          <Route
            path="/system_setting_restaurants/:id"
            element={<SystemSettings />}
          />
          <Route
            path="/view_resturant_reviews/:id"
            element={<RestaurantReviews />}
          />

          {/* Website Settings Pages */}
          <Route path="/Website_Management" element={<WebsiteMngt />} />

          {/* Create, Edit and View Righst Pages */}
          <Route path="/Create_Staff_Rights" element={<CreateStaffRights />} />
          <Route path="/View_Staff_Rights" element={<ViewStaffRights />} />
          <Route
            path="/Edit_Staff_Rights/:id"
            element={<CreateStaffRights />}
          />
          <Route
            path="/Create_SubStaff_Rights/:id"
            element={<CreateSubStaffRights />}
          />
          <Route path="/View_Sub_Rights/:id" element={<ViewSubRights />} />
          <Route
            path="/Edit_SubStaff_Rights/:id"
            element={<CreateSubStaffRights />}
          />
          <Route path="/Edit_Sub_Rights" element={<EditSubRights />} />
          <Route
            path="/View_Inside_Page_Rights/:id"
            element={<InsidePageRightsView />}
          />
          <Route
            path="/Create_Inside_Page_Rights/:id"
            element={<CreateInsidePageRights />}
          />

          <Route path="/Add_Designation" element={<AddDesignation />} />
          <Route path="/Edit_Designation/:id" element={<AddDesignation />} />
          <Route path="/ViewDesignation" element={<ViewDesignation />} />

          <Route path="/Analytics" element={<Analytics />} />
          {/* Help And Support Pages */}
          <Route path="/Manuals" element={<Manuals />} />
          <Route path="/Add_Faq" element={<Add_Faqs />} />
          <Route path="/View_Faq" element={<ViewFaq />} />
          <Route path="/View_KB" element={<ViewKB />} />
          <Route path="/Add_KB" element={<AddKB />} />
          <Route path="/Edit_KB/:id" element={<AddKB />} />
          <Route path="/Edit_Faq/:id" element={<Add_Faqs />} />

          <Route path="/cuisines_view" element={<CuisinesView />} />
          <Route path="/cuisines_add" element={<CuisinesAdd />} />
          <Route path="/cuisines_edit/:id" element={<CuisinesAdd />} />
          <Route path="/collection_view" element={<CollectionsView />} />
          <Route path="/collection_add" element={<CollectionsAdd />} />
          <Route path="/collection_edit/:id" element={<CollectionsAdd />} />
          <Route path="/amenities_view" element={<AmenitiesView />} />
          <Route path="/amenities_add" element={<AmenitiesAdd />} />
          <Route path="/amenities_edit/:id" element={<AmenitiesAdd />} />
          <Route path="/amenities_edit/:id" element={<AmenitiesAdd />} />
          <Route path="/city_view" element={<CityView />} />
          <Route path="/city_add" element={<CityAdd />} />
          <Route path="/city_edit/:id" element={<CityAdd />} />
          <Route path="/country_view" element={<CountryView />} />

          {/* Search Master */}
          <Route path="/ViewSearchMaster" element={<ViewSearchMaster />} />
          <Route path="/Add_SearchMaster" element={<Add_SearchMaster />} />
          <Route path="/Edit_SearchMaster/:id" element={<Add_SearchMaster />} />

          {/* Analytics and Reports Pages */}
          <Route path="/Dashboard" element={<Dashboard />} />

          <Route path="/help" element={<Help />} />
          <Route path="/Manuals/Create_Ticket" element={<CreateNewTicket />} />
          <Route
            path="/Manuals/Reply_Ticket/:ticketId"
            element={<ReplyToTicket />}
          />

          <Route path="/View_Reports" element={<Report />} />
          <Route
            path="/View_Restaurant_enquiry"
            element={<RestaurantEnquiry />}
          />
          <Route path="/View_Reservation" element={<ReservationHistory />} />
          <Route path="/View_landmark" element={<LandMarkView />} />
          <Route path="/add_landmark" element={<LandMarkAdd />} />
          <Route path="/edit_landmark/:id" element={<LandMarkAdd />} />
          <Route path="/view_notification" element={<NotificationMngmt />} />

          {/**----------Add Restaurant------------ */}
          <Route path="/View_Onboading" element={<OnboadingSideTab />} />
          <Route path="/add_restaurant_new" element={<AddRestaurantNew />} />
          <Route path="/Edit_restaurant_new/:id/:id2" element={<AddRestaurantNew />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
