import React, { useState, useEffect, useCallback } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import { useDropzone } from "react-dropzone";
import crossmodalImg from "../assets/crossmodalImg.png";
import imgUploadIcon from "../assets/imgUploadIcon.svg";
import { AddStaffPageText } from "../CommonJquery/WebsiteText.js";
import { Modal, Button } from "react-bootstrap";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  empty_form,
  cencelChanges,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  save_resturant_images_menu,
  get_all_new_resturant,
  delete_resturant_images_menu,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
function CreateRestroImages() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [selectedImages, SetselectedImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      files.forEach(({ file, keyname }) => {
        fd_from.append("images_loop", file, file.name); // Include original filename
      });
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("flag_key", "0");
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_Restaurants");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleDeactivate = () => {
    master_data_action_update(SelectedData.primary_id, SelectedData.image_name);
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };

  const master_data_action_update = async (call_id, image_name) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("image_id", call_id);
    fd.append("image_name", image_name);
    fd.append("flag_key", "0");
    await server_post_data(delete_resturant_images_menu, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          const url = currentUrl;
          const parts = url.split("/");
          const start_date = "";
          const end_date = "";
          const flag = "3";
          let call_id = "0";
          if (parts.length !== 1) {
            call_id = parts[1];
          }
          master_data_get(start_date, end_date, flag, call_id);
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_new_resturant, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_restaurant.length > 0) {
            seteditStaffData(Response.data.message.data_restaurant[0]);
            setEditorDatMainID(
              Response.data.message.data_restaurant[0].primary_id
            );
            SetselectedImages(Response.data.message.restaurant_images);
            setsStaffImageLinkData(
              APL_LINK + Response.data.message.data_restaurant_image
            );
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const removeImage = (index, from_where) => {
    if (from_where === 0) {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
    } else {
      handleShow(index);
    }
  };
  const thumbs = files.map((file, index) => (
    <div key={file.name} className="thumb">
      <img src={file.preview} alt={file.name} className="thumb-img" />
      <img
        src={crossmodalImg}
        alt="Remove"
        className="remove-icon"
        onClick={() => removeImage(index, 0)}
      />
    </div>
  ));

  const handleDropFiles = (acceptedFiles) => {
    // Update state with new files
    const fileCount = files.length;

    const newFiles = acceptedFiles
      .filter((file) => file instanceof File)
      .map((file, index) => {
        const keyname = `file_${fileCount + index}`;
        const preview = file.type.startsWith("image/")
          ? URL.createObjectURL(file)
          : null;

        return {
          file,
          keyname,
          preview,
        };
      });

    // Update state with new files and their previews
    setFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map(({ file, keyname, preview }) => ({
        file,
        keyname,
        preview,
      })),
    ]);
  };

  // Handlers for the first upload section
  const { getRootProps: getFirstRootProps, getInputProps: getFirstInputProps } =
    useDropzone({
      multiple: true,
      accept: "image/*",
      onDrop: handleDropFiles,
    });

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>
                  Upload Restaurant Images {editStaffData.restaurant_name} (
                  {editStaffData.restaurant_city})
                </p>
                <img src={GreyArrow} alt="Dfoodo Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="addStaffForms">
                <form id="addNewStaff">
                  {/* First Upload Section */}
                  <div className="d-flex align-items-center gap-3">
                    {selectedImages.map((option, index) => (
                      <div className="card_imagesUpload" key={index}>
                        <img
                          src={StaffImageLinkData + option.image_name}
                          alt={option.image_name}
                          style={{ width: "100px", height: "100px" }}
                        />
                        <div className="container_card">
                          <img
                            src={crossmodalImg}
                            alt="Remove"
                            className="remove-icon22222"
                            onClick={() => removeImage(option, 1)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <div className="main-card mb-3 card firstUpload">
                        <div className="card-header card-header-view">
                          <div className="btn-actions-pane-left row"></div>
                          <div className="d-flex gap-3">
                            <aside className="thumbs-container">{thumbs}</aside>
                            <div style={{ margin: "0.5rem" }}>
                              <div
                                {...getFirstRootProps({
                                  className: "dropzone",
                                })}
                              >
                                <input
                                  {...getFirstInputProps({ multiple: true })}
                                  className="input-file"
                                  id="files_finals"
                                  name="files_finals[]"
                                />
                                {files.length === 0 ? (
                                  <p className="d-flex flex-column align-items-center">
                                    <img
                                      className="trio_mandatory form-control upload"
                                      src={imgUploadIcon}
                                      alt="Upload"
                                      id="files"
                                      name="files[]"
                                    />
                                    <label className="upladImgLabel">
                                      Add Images
                                    </label>
                                  </p>
                                ) : (
                                  <p>Add More Images</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div
                            {...getFirstRootProps({ className: "dropzone" })}
                          >
                            <input
                              {...getFirstInputProps({ multiple: true })}
                              className="input-file"
                              id="files_finals"
                              name="files_finals[]"
                            />
                            {files.length === 0 ? (
                              <p>
                                <img
                                  className="trio_mandatory form-control upload"
                                  src={imgUploadIcon}
                                  alt="Upload"
                                  id="files"
                                  name="files[]"
                                />
                                <label className="upladImgLabel">
                                  Upload Restaurant Images
                                </label>
                              </p>
                            ) : (
                              <p>Selected Images</p>
                            )}
                          </div>
                          <aside className="thumbs-container">{thumbs}</aside> */}
                          <span style={{ color: "red", fontWeight: "600" }}>
                            (size 1000 * 650 up to 1 MB)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="saveFormBtns">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={cencelChanges}
                    >
                      {AddStaffPageText.cencel_text}
                    </button>

                    <button
                      className="btnSave"
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "addNewStaff",
                          save_resturant_images_menu
                        )
                      }
                    >
                      {location.pathname.includes("/edit_staff")
                        ? AddStaffPageText.update_text
                        : AddStaffPageText.save_text}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Delete This Data?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button className={` logoutNoBtn`} onClick={handleClose}>
            No
          </Button>
          <Button className={` logoutYesBtn`} onClick={handleDeactivate}>
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateRestroImages;
