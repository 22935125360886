import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import backButton from "../assets/backButton.svg";
import dine1 from "../assets/dine1.svg";
import dine2 from "../assets/dine2.svg";
import dine3 from "../assets/dine3.svg";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep3 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const restaurantOptions = [
    {
      img: dine1,
      title: "Dine In",
      description: "Customer can book table through D foodo",
    },
    {
      img: dine2,
      title: "Take Away",
      description: "Customer can Order online from Restaurant",
    },
    {
      img: dine3,
      title: "Delivery",
      description: "Customer can order online and will Deliver",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState(
    new Array(restaurantOptions.length).fill(false)
  );

  const handleCheckboxChange = (index) => {
    const updatedSelection = [...selectedOptions];
    updatedSelection[index] = !updatedSelection[index];
    setSelectedOptions(updatedSelection);
  };

  const handleNextStep = () => {
    const isAnySelected = selectedOptions.some((isSelected) => isSelected);
    console.log(selectedOptions);
    if (!isAnySelected) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please choose at least one option!",
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    } else {
      handleSaveChangesdynamic();
    }
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_dinein", selectedOptions[0] ? "1" : "0");
    form_data.append("restaurant_takeaway", selectedOptions[1] ? "1" : "0");
    form_data.append("restaurant_delivery", selectedOptions[2] ? "1" : "0");
    form_data.append("step_save", "3");

    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setSelectedOptions([
              Response.data.message.restaurant_data[0].restaurant_dinein === 0
                ? false
                : true,
              Response.data.message.restaurant_data[0].restaurant_takeaway === 0
                ? false
                : true,
              Response.data.message.restaurant_data[0].restaurant_delivery === 0
                ? false
                : true,
            ]);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const isAnyOptionSelected = selectedOptions.some((option) => option === true);
  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>
            <h3>Choose Your Restaurant Type</h3>
          </div>
          <p>Select all that apply to your Restaurant</p>
        </div>
        <div className="overflowHeight">
          {restaurantOptions.map((option, index) => (
            <div key={index} className="col-sm-9 mb-3">
              <div className="box1">
                <div className="row align-items-center">
                  <div className="col-2">
                    <img
                      src={option.img}
                      alt={option.title}
                      className="dinImg"
                    />
                  </div>
                  <div className="col">
                    <div className="AllContent">
                      <p>{option.title}</p>
                      <label>{option.description}</label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inputCHeck">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={selectedOptions[index]}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <span className="custom-checkbox"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="NextButton fixNextButton">
            <div className="container-lg d-flex justify-content-end">
              <button
                onClick={handleNextStep}
                disabled={!isAnyOptionSelected}
                style={{
                  opacity: !isAnyOptionSelected ? 0.5 : 1,
                  cursor: !isAnyOptionSelected ? "not-allowed" : "pointer",
                  pointerEvents: !isAnyOptionSelected ? "none" : "auto",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep3;
