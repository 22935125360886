import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import EmployeeImg from "../assets/storeNew1.png";
import FilterIcon from "../assets/filterIcon.png";
import actionSetting from "../assets/actionSetting.svg";
import installIcon from "../assets/installIcon.svg";
import Search from "../assets/search.png";
import { Link } from "react-router-dom";
import Editred from "../assets/editred.svg";

import GreyArrow from "../assets/greyLeftAr.png";
import { Modal, Button } from "react-bootstrap";
import {
  server_post_data,
  action_update_resturant,
  new_restaurant_install_btn,
  get_all_new_resturant,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  computeTodayDate,
  handleAphabetsChange,
  handleError,
  inputdateformateChange,
  formatTimeintotwodigit,
  handleLinkClick,
  handleEmailChange,
  handleNumbersChange,
  handleIaphabetnumberChange,
  formatDateStringdot,
  formatTimeFormatcustom,
  computeplussevendays,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import {
  ViewStaffPagesText,
  options_search,
  GuestManagementPageText,
} from "./../CommonJquery/WebsiteText";
function RestaurantSetup() {
  let flag = "1";
  let formattedSevenDaysAgo = computeplussevendays();
  let formattedToday = computeTodayDate();

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedValue2, setSelectedValue2] = useState("0"); // State to hold the selected value
  const [editStaffData, seteditStaffData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const [searchfilter, setSearchFilter] = useState("");
  const [SelectedData, setSelectedData] = useState([]);
  const [ProductOptions, setProductOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_new_resturant, fd)
      .then((Response) => {
        // console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_restaurant.forEach((subObj) => {
            if (subObj.active_status === 1) {
              subObj.activedata = "Active";
            } else {
              subObj.activedata = "Deactive";
            }
          });
          seteditStaffData(Response.data.message.data_restaurant);
          setfilteredData(Response.data.message.data_restaurant);
          setProductOptions(Response.data.message.data_restaurant_options);
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_restaurant_image
          );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };
  const handleSelect2 = (event) => {
    setSelectedValue2(event.target.value); // Update the selected value when an option is selected
  };
  const search_data = () => {
    flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };

  const [showModal, setShowModal] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(1);

  const openModal = (guestName, index, flag) => {
    let tesr_show = " Not ";
    if (guestName.active_status === 0) {
      tesr_show = "";
    }
    tesr_show = tesr_show + "  as Active ";
    setSelectedData(guestName);
    if (flag === 1) {
      setShowModal(true);
    } else {
      setShowModal2(true);
    }

    setClickedButton(guestName.restaurant_name + tesr_show);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index) => {
    let allow_access_data = "0";
    if (SelectedData.active_status === 0) {
      allow_access_data = "1";
    }

    master_data_action_update(SelectedData, allow_access_data, 1);
  };

  const confirmVIP2 = (index, flag) => {
    let allow_access_data = selectedValue2;
    master_data_action_update(SelectedData, allow_access_data, 2);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal2(false);
  };

  const master_data_action_update = async (
    call_id,
    for_status_final,
    flag_for
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id)
    fd.append("id_for_delete", call_id.primary_id);
    fd.append("email", call_id.restaurant_owner_email);
    fd.append("name", call_id.restaurant_owner_name);
    fd.append("flag_for", flag_for);
    fd.append("for_status_final", for_status_final);
    await server_post_data(action_update_resturant, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const InstallRestaurant = async (
    call_id,
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", call_id);
    await server_post_data(new_restaurant_install_btn, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          handleSuccess(Response.data.message);
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="reservationHistory" style={{ overflow: "hidden" }}>
      {showLoaderAdmin && <Loader />}
      <Header />
      <div className="pageCntn_head">
        <div className="pageNameDiv">
          <p>{GuestManagementPageText.Restaurant_Management}</p>
          <img src={GreyArrow} alt="Dfoodo Dashboard" />
        </div>
        {/* <Link to="/add_restaurant_new">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Add Restaurant"
            className="createDiningArea createRestaurant Add_Restaurant"
          >
            Add Restraurant
          </button>
        </Link> */}
      </div>
      <div className="viewStaff_head">
        <div className="row m-0">
          <div className="col-lg-2 col-sm-4 bottomAlgin">
            <label className="labelView">{ViewStaffPagesText.Start_Date}</label>
            <div className="person__calenderFrame_image image_icon_position1 ">
              <input
                id="startDate"
                type="date"
                placeholder={ViewStaffPagesText.D_O_B}
                className="form-control  input_field_custom4"
                defaultValue={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={computeTodayDate()}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 bottomAlgin">
            <label className="labelView">{ViewStaffPagesText.End_Date}</label>
            <div className="person__calenderFrame_image image_icon_position1 ">
              <input
                id="endDate"
                type="date"
                placeholder={ViewStaffPagesText.D_O_B}
                className="form-control  input_field_custom4"
                defaultValue={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                max={computeTodayDate()}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-4 bottomAlgin">
            <div className="inputDiv2">
              <label className="labelPointer" htmlFor="selectFilter">
                <img src={FilterIcon} alt="Dfoodo Dashboard" />
              </label>
              <select id="selectFilter" onChange={handleSelect}>
                {options_search.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-5 col-sm-8 bottomAlgin">
            <div className="inputDiv2">
              <img src={Search} alt="Dfoodo Dashboard" />
              <input
                type="text"
                id="search_data"
                onInput={(e) => {
                  if (selectedValue === options_search[0].value) {
                    handleAphabetsChange(e);
                  } else if (selectedValue === options_search[1].value) {
                    handleEmailChange(e);
                  } else if (selectedValue === options_search[2].value) {
                    handleNumbersChange(e);
                  }
                }}
                placeholder={ViewStaffPagesText.Search_Employee_reservation}
              />
              <button
                type="button"
                className="btnSearch"
                onClick={() => search_data()}
              >
                {ViewStaffPagesText.Search_text}
              </button>
            </div>
          </div>

          <div
            className="col-md-4 centerAlgin"
            style={{ marginLeft: "auto", marginRight: "0" }}
          >
            <div className="inputDiv2">
              <label className="labelPointer" htmlFor="selectFilter">
                <img src={FilterIcon} alt="Dfoodo Dashboard" />
              </label>
              <input
                type="text"
                placeholder={ViewStaffPagesText.Staff_Filter_reservation}
                onInput={handleIaphabetnumberChange}
                value={searchfilter}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="viewGuest_table mt-4 ">
        <div className="viewGuest_table_container ">
          <div className="row m-0">
            <div className="col-md-12 table-responsive stickyTableheight">
              <table className="table">
                <thead className="stickyHeaderClss">
                  <tr>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Restaurant Details
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingRight: "1rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Contact Details
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "0.3rem",
                      }}
                    >
                      Email
                    </th>

                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                    >
                      View Options
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "1rem",
                        paddingRight: "2rem",
                      }}
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        paddingLeft: "1rem",
                        paddingRight: "rem",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((option, index) => (
                    <>
                      <React.Fragment key={index}>
                        <tr className="tableRow tbodyStyle">
                          <td className="th1">
                            <div className="rsvnDetails saparator1">
                              <div className="rsvnDetailsImg">
                                <img
                                  src={
                                    StaffImageLinkData + option.restaurant_image
                                  }
                                  onError={(e) => (e.target.src = EmployeeImg)}
                                  alt="Dfoodo Dashboard"
                                />
                              </div>
                              <div className="rsvnDetailsText">
                                <h6>{option.restaurant_name}</h6>
                                <p>{option.restaurant_tagline}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="rsvnDetails">
                              <div className="rsvnContactDetails">
                                <p>{option.restaurant_mobile_no}</p>
                                {option.restaurant_other_no &&
                                  option.restaurant_other_no.length > 2 && (
                                    <p>{option.restaurant_other_no}</p>
                                  )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="rsvnDetails">
                              <div className="rsvnContactDetails">
                                <p>{option.restaurant_email}</p>
                              </div>
                            </div>
                          </td>

                          <td className="th2 tabledata">
                            <div className="actionsAlign">
                              <div className="guest_incenterActions borderRightRadius">
                                <button
                                  type="button"
                                  className={`markVip Mark_Vip backBlue`}
                                  onClick={() => openModal(option, index, 3)}
                                >
                                  <p>
                                    {ProductOptions.map((optionssss) =>
                                      optionssss.value ===
                                        option.restaurant_type
                                        ? optionssss.label
                                        : null
                                    ).filter((label) => label !== null)}
                                  </p>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="th2 tabledata">
                            <div className="actionsAlign">
                              <div className="guest_incenterActions borderRightRadius  position-relative">
                                <div
                                  className={`toggleForSttus ${option.active_status === 1
                                      ? "ShowAfterActive"
                                      : "ShowDective"
                                    }`}
                                  onClick={() => openModal(option, 1, 1)}
                                >
                                  <input
                                    type="checkbox"
                                    id={`switch-${index}`}
                                    checked={option.active_status === 1}
                                  />
                                  <label htmlFor={`switch-${index}`}>
                                    {option.activedata}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="th2 tabledata">
                            <div className="Actionbttns">
                              {option.active_status === 1 && (
                                <button onClick={() => handleShow(option)}>
                                  <img
                                    src={actionSetting}
                                    alt="Action"
                                    title="Action"
                                  ></img>
                                </button>
                              )}
                              {/* {option.restaurant_launch_status === 0 &&(
                                <button onClick={() => InstallRestaurant(option.primary_id)}>
                                  <img
                                    src={installIcon}
                                    alt="Install"
                                    title="Install"
                                  ></img>
                                </button>
                              )} */}
                            </div>
                          </td>
                        </tr>
                        <tr
                          //   key={`spacer-${index}`}
                          style={{ height: "1rem" }}
                        ></tr>
                      </React.Fragment>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>
            Are you sure you want to mark{" "}
            <span style={{ color: "#e33745" }}>{clickedButton}</span>
            Restaurant?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal2} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="w-100 success_img d-flex justify-content-center">
            <select
              id="selectFilter "
              className="input_field_custom5 selectFilter3 w-100 height_padding"
              onChange={handleSelect2}
            >
              {ProductOptions.length > 0 &&
                ProductOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={SelectedData.restaurant_type === option.value}
                  >
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP2(selectedGuestIndex, 2)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody pt-0">
          <div className="recentReservBtns recentReservBtnsddddd modalBtnsH justify-content-start">
            <button
              type="button"
              style={{
                width: "106px",
                placeContent: "center",
              }}
              onClick={() =>
                handleLinkClick(`/edit_restaurants/${SelectedData.primary_id}`)
              }
            >
              <p>Edit</p>
            </button>
            <button
              type="button"
              style={{
                width: "106px",
                placeContent: "center",
              }}
              onClick={() =>
                handleLinkClick(
                  `/edit_restaurants_menu/${SelectedData.primary_id}`
                )
              }
            >
              <p>Menu Upload</p>
            </button>
            <button
              type="button"
              style={{
                width: "106px",
                placeContent: "center",
              }}
              onClick={() =>
                handleLinkClick(
                  `/edit_restaurants_images/${SelectedData.primary_id}`
                )
              }
            >
              <p>Image Upload</p>
            </button>

            <button
              type="button"
              style={{
                width: "106px",
                placeContent: "center",
              }}
              onClick={() =>
                handleLinkClick(
                  `/system_setting_restaurants/${SelectedData.primary_id}`
                )
              }
            >
              <p>Setting</p>
            </button>
            <button
              type="button"
              style={{
                width: "106px",
                placeContent: "center",
              }}
              onClick={() =>
                handleLinkClick(
                  `/view_resturant_reviews/${SelectedData.primary_id}`
                )
              }
            >
              <p>Reviews</p>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RestaurantSetup;
