import React, { useState, useEffect } from "react";
import backButton from "../assets/backButton.svg";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError, handleNumbersChange } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep5 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [ownerDetails, setOwnerDetails] = useState({
    ownerName: "",
    ownerEmail: "",
    countryCode: "+91",
    contactNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [shake, setShake] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOwnerDetails((prev) => ({ ...prev, [name]: value }));
    if (value !== "") {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };
  const handleMobilevalidation = (e) => {
    const { name, value } = e.target;

    if (name === "contactNumber" && !/^\d*$/.test(value)) {
      return;
    }

    handleChange(e);
  };
  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    setOwnerDetails((prev) => ({ ...prev, [name]: value }));

    // Email validation
    if (!emailRegex.test(value)) {
      setErrors((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }

    // Clear error if input is not empty
    if (value === "") {
      setErrors((prev) => ({ ...prev, [name]: true }));
    }
  };
  const handleFocus = (field) => {
    setFocusedField(field);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const handleNextStep = () => {
    const newErrors = {};
    let hasError = false;

    Object.keys(ownerDetails).forEach((field) => {
      if (field !== "ownerEmail" && ownerDetails[field] === "") {
        newErrors[field] = true;
        hasError = true;
      }
    });

    if (
      ownerDetails.ownerEmail === "" ||
      !emailRegex.test(ownerDetails.ownerEmail)
    ) {
      newErrors.ownerEmail = true;
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } else {
      handleSaveChangesdynamic();
    }
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_owner_name", ownerDetails.ownerName);
    form_data.append("restaurant_owner_email", ownerDetails.ownerEmail);
    form_data.append("restaurant_owner_code", ownerDetails.countryCode);
    form_data.append("restaurant_owner_mobile", ownerDetails.contactNumber);
    form_data.append("step_save", "5");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setOwnerDetails({
              ownerName:
                Response.data.message.restaurant_data[0].restaurant_owner_name,
              ownerEmail:
                Response.data.message.restaurant_data[0].restaurant_owner_email,
              countryCode:
                Response.data.message.restaurant_data[0].restaurant_owner_code,
              contactNumber:
                Response.data.message.restaurant_data[0]
                  .restaurant_owner_mobile,
            });
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Function to handle input change for the country code
  const handleCountryCodeChange = (e) => {
    const value = e.target.value;

    // Only allow '+', followed by digits, and prevent entering more than 3 characters
    const regex = /^\+?[0-9]{0,2}$/;

    if (regex.test(value)) {
      handleChange(e); // Update value if it's valid
    }
  };
  const isButtonDisabled = () => {
    return (
      errors.ownerName ||
      errors.ownerEmail ||
      errors.contactNumber ||
      ownerDetails.ownerName === "" ||
      ownerDetails.ownerEmail === "" ||
      ownerDetails.contactNumber === ""
    );
  };
  return (
    <div>
      <div className="mt-3">
        <div className="containerRight">
          <div className="rightHeading">
            <div className="d-flex BackHeading">
              <button onClick={prevStep}>
                <img src={backButton} alt="backButton" />
              </button>
              <h3>Restaurant Owner Details</h3>
            </div>
            <p>It helps you to manage Dashboard</p>
          </div>
          <div className="overflowHeight">
            <div className="col-sm-9 mb-3">
              <div style={{ position: "relative" }}>
                {(focusedField === "ownerName" || ownerDetails.ownerName) && (
                  <label className="HeadBussiness">
                    Restaurant Owner Name*
                  </label>
                )}
                <input
                  type="text"
                  name="ownerName"
                  placeholder="Restaurant owner name*"
                  className={`form-control ${
                    errors.ownerName ? "input-error" : ""
                  } ${shake ? "shake" : ""}`}
                  value={ownerDetails.ownerName}
                  onFocus={() => handleFocus("ownerName")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.ownerName && (
                  <span className="error-message">Owner name is required</span>
                )}
              </div>
            </div>

            <div className="col-sm-9 mb-3">
              <div style={{ position: "relative" }}>
                {(focusedField === "ownerEmail" || ownerDetails.ownerEmail) && (
                  <label className="HeadBussiness">
                    Restaurant Owner Email*
                  </label>
                )}
                <input
                  type="text"
                  name="ownerEmail"
                  placeholder="Restaurant Owner Email*"
                  className={`form-control ${
                    errors.ownerEmail ? "input-error" : ""
                  } ${shake ? "shake" : ""}`}
                  value={ownerDetails.ownerEmail}
                  onFocus={() => handleFocus("ownerEmail")}
                  onBlur={handleBlur}
                  onChange={handleEmailChange}
                />
                {errors.ownerEmail && (
                  <span className="error-message">Email is required</span>
                )}
              </div>
            </div>

            <div className="col-sm-9 mb-3">
              <div className="row">
                <div className="col-3" style={{ position: "relative" }}>
                  {/* {(focusedField === "countryCode" ||
                      ownerDetails.countryCode) && (
                      <label className="HeadBussiness">Country Code</label>
                    )} */}
                  <input
                    type="text"
                    name="countryCode"
                    placeholder="+91"
                    maxLength={3}
                    className={`form-control ${
                      errors.countryCode ? "input-error" : ""
                    } ${shake ? "shake" : ""}`}
                    value={ownerDetails.countryCode}
                    onFocus={() => handleFocus("countryCode")}
                    onBlur={handleBlur}
                    onChange={(e) => handleCountryCodeChange(e)}
                  />

                  {errors.countryCode && (
                    <span className="error-message">
                      Country code is required
                    </span>
                  )}
                </div>
                <div
                  className="col-9"
                  style={{ position: "relative", paddingLeft: "0" }}
                >
                  {(focusedField === "contactNumber" ||
                    ownerDetails.contactNumber) && (
                    <label className="HeadBussiness">Contact Number*</label>
                  )}
                  <input
                    type="text"
                    minLength={10}
                    maxLength={10}
                    name="contactNumber"
                    placeholder="Contact Number*"
                    className={`form-control ${
                      errors.contactNumber ? "input-error" : ""
                    } ${shake ? "shake" : ""}`}
                    value={ownerDetails.contactNumber}
                    onFocus={() => handleFocus("contactNumber")}
                    onBlur={handleBlur}
                    onChange={handleMobilevalidation}
                  />
                  {errors.contactNumber && (
                    <span className="error-message">
                      Contact number is required
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="NextButton fixNextButton">
              <div className="container-lg d-flex justify-content-end">
                <button
                  onClick={handleNextStep}
                  style={{
                    opacity: isButtonDisabled() ? 0.5 : 1,
                    pointerEvents: isButtonDisabled() ? "none" : "auto",
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep5;
