import React, { useState, useEffect } from "react";
import OpenMenu from "../assets/menu_open.png";
import Dashboard from "../assets/sideDashblack.svg";
import DashboardRed from "../assets/DashboardRed.svg";
import Analytics from "../assets/AnalyticsIcn.svg";
import AnalyticsIcnRed from "../assets/AnalyticsIcnRed.svg";
import OnboadingIcn from "../assets/OnboadingIcn.svg";
import OnboadingIcnRed from "../assets/OnboadingIcnRed.svg";
import SubMangeIcn from "../assets/SubMangeIcn.svg";
import SubMangeIcnRed from "../assets/SubMangeIcn.svg";
import RestaurntManagIcn from "../assets/RestaurntManagIcn.svg";
import RestaurntManagIcnRed from "../assets/RestaurntManagIcnRed.svg";
import ReserVationIcn from "../assets/ReserVationIcn.svg";
import ReserVationIcnRed from "../assets/ReserVationIcnRed.svg";
import RestEquiry from "../assets/RestEquiry.svg";
import RestEquiryRed from "../assets/RestEquiryRed.svg";
import UserMangeIcn from "../assets/UserMangeIcn.svg";
import UserMangeIcnRed from "../assets/UserMangeIcnRed.svg";
import StaffMangIcn from "../assets/StaffMangIcn.svg";
import StaffMangIcnRed from "../assets/StaffMangIcnRed.svg";
import MasterSetupIcn from "../assets/MasterSetupIcn.svg";
import MasterSetupIcnRed from "../assets/MasterSetupIcnRed.svg";
import AreaManageIcn from "../assets/AreaManageIcn.svg";
import AreaManageIcnRed from "../assets/AreaManageIcnRed.svg";
import Notifiicn from "../assets/Notifiicn.svg";
import NotifiicnRed from "../assets/NotifiicnRed.svg";
import ReportIcn from "../assets/ReportIcn.svg";
import ReportIcnRed from "../assets/ReportIcnRed.svg";
import WebMangrIcn from "../assets/WebMangrIcn.svg";
import WebMangrIcnRed from "../assets/WebMangrIcnRed.svg";
import dropArroww from "../assets/dropArrow.svg";
import dropArrowwOrange from "../assets/dropArrowOrange.svg";
import HelpIcn from "../assets/HelpIcn.svg";
import HelpIcnRed from "../assets/HelpIcnRed.svg";
import logoIcon from "../assets/logoIcon.svg";
import { sidebarpagetext } from "./../CommonJquery/WebsiteText";
import { Link, useLocation } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import {
  server_post_data,
  get_all_StaffRights,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function SideBar({ isSidebarOpen, toggleSidebar }) {
  const location = useLocation();
  const access_permission = retrieveData("access_permission");
  const [page_chekt, setPage_chekt] = useState([]);
  const [rightpage, setrightpage] = useState([]);
  const [designationpage, setDesignationPage] = useState([]);
  useEffect(() => {
    master_data_get_staff_right();
  }, []);

  /******************* this useEffect Staff Right Activity Start****************/
  useEffect(() => {
    //console.log(page_chekt);
    //console.log(designationpage);
    var currentPage = window.location.pathname.split("/").pop();
    var rrr = Triosidebarhideshow(currentPage);
    if (rrr) {
      goback();
    }

    if (page_chekt[currentPage] && page_chekt[currentPage]["insidepage"]) {
      page_chekt[currentPage]["insidepage"].split("/").forEach(function (item) {
        console.log(item);
        const insidePages_vw = item.split("@");
        if (
          designationpage[currentPage] &&
          Object.values(designationpage[currentPage]).includes(
            insidePages_vw[0]
          )
        ) {
          // item is present in the designation so no need to remove
        } else {
          var elementsToRemove = document.querySelectorAll(
            "." + insidePages_vw[0]
          );
          elementsToRemove.forEach(function (element) {
            element.remove(); //remove buttons
          });
        }
      });
    }

    Object.keys(page_chekt).forEach(function (key) {
      var itempi = page_chekt[key].page_url_id;
      if (!designationpage[itempi]) {
        document.querySelectorAll(`li.${itempi}`).forEach(function (element) {
          element.remove();
        });
        document
          .querySelectorAll(`[href="/${itempi}"]`)
          .forEach(function (element) {
            element.remove();
          });
      }
    });
  }, [page_chekt]);

  /******************* this useEffect Staff Right Activity End ****************/

  const master_data_get_staff_right = async () => {
    const fd = new FormData();
    fd.append("flag", "10");
    await server_post_data(get_all_StaffRights, fd)
      .then((Response) => {
        // Assuming Response.data is an array of objects with 'page_url' as key
        Response.data.message.data.forEach(function (item) {
          var idd = item.page_url_id;
          setPage_chekt((prevState) => ({
            ...prevState,
            [idd]: {
              page_url_id: item.page_url_id,
              insidepage: item.insidepage,
            },
          }));
        });
        // Now you have page_chekt object in JavaScript

        const datam = access_permission.split("/");
        const newDataxt = datam.reduce((acc, item, index) => {
          if (item.includes("@")) {
            const [page, ...extras] = item.split("@");
            if (!acc[page]) {
              acc[page] = {};
            }
            extras.forEach((extra, idx) => {
              acc[page][idx] = extra;
            });
          } else {
            acc[item] = { ["class_list"]: "" };
          }
          return acc;
        }, {});
        setDesignationPage(newDataxt);

        if (Response.data.error) {
          //handleError(Response.data.message);
        } else {
          setrightpage(Response.data.message.data);
        }
      })
      .catch((error) => {});
  };

  function Triosidebarhideshow(pagelink) {
    if (designationpage[pagelink]) {
      return ""; // User has access to this page
    } else {
      if (!page_chekt[pagelink]) {
        return ""; // No user right for this page
      } else {
        return "dsplynoe"; // User has no right to access this page
      }
    }
  }

  /********* Current Page redirect Start**********/
  function goback() {
    window.history.back();
  }
  /********* Current Page redirect Close**********/
  return (
    <div className={`sidebar ${isSidebarOpen ? "OpneSidebar" : ""}`}>
      <div className="sidebar_container">
        <div className="sideHeadLogo">
          <a className="navbar-brand" href="#">
            <div className="headerLogo">
              <div className="headerLogo_contaienr">
                <img src={logoIcon} alt="Dfoodo Dashboard" />
              </div>
            </div>
          </a>
        </div>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          htmlFor="top"
          title={`Hide`}
          className="menuOpenClose"
          onClick={toggleSidebar}
          style={{
            paddingLeft: "0",
            marginLeft: "2rem",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ transform: " rotate(0deg)" }}
            src={OpenMenu}
            alt="Dfoodo Dashboard"
          />
        </div>
        <div className="sidebar_list">
          <ul>
            <Link to="/Dashboard">
              <li className="analytics">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Dashboard" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Dashboard"
                        ? DashboardRed
                        : Dashboard
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>{sidebarpagetext.Dashborard_text}</p>
                </div>
              </li>
            </Link>
            <Link to="/Analytics">
              <li className="analytics">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Analytics" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Analytics"
                        ? AnalyticsIcnRed
                        : Analytics
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>Analytics</p>
                </div>
              </li>
            </Link>
            {/**---Onboading---------- */}
            <Link onClick={() => handleLinkClick("/View_Onboading")}>
              <li
                className={`restaurant_mngmt ${Triosidebarhideshow(
                  "Restaurant_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/add_restaurant_new" ||
                    location.pathname === "/View_Onboading" ||
                    location.pathname.includes("/Edit_restaurant_new")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname.includes("/View_Onboading")
                        ? OnboadingIcnRed
                        : OnboadingIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>On Boadings</p>
                </div>
              </li>
            </Link>

            {/**----------------------Subscribtion------------------- */}
            <Link onClick={() => handleLinkClick("/View_Subsciption")}>
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Subscription_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Subsciption"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Subsciption"
                        ? SubMangeIcnRed
                        : SubMangeIcn
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>Subscription Mngmt</p>
                </div>
              </li>
            </Link>
            {/**--------------------Restaurent Management------------------------- */}
            <Link onClick={() => handleLinkClick("/View_Restaurants")}>
              <li
                className={`restaurant_mngmt ${Triosidebarhideshow(
                  "Restaurant_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Create_Restaurant" ||
                    location.pathname === "/View_Restaurants" ||
                    location.pathname.includes("/edit_restaurants") ||
                    location.pathname.includes("/edit_restaurants_menu") ||
                    location.pathname.includes("/edit_restaurants_images") ||
                    location.pathname.includes("/system_setting_restaurants") ||
                    location.pathname.includes("/view_resturant_reviews")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Restaurants" ||
                      location.pathname.includes("/edit_restaurants") ||
                      location.pathname.includes("/edit_restaurants_menu") ||
                      location.pathname.includes("/edit_restaurants_images") ||
                      location.pathname.includes(
                        "/system_setting_restaurants"
                      ) ||
                      location.pathname.includes("/view_resturant_reviews")
                        ? RestaurntManagIcnRed
                        : RestaurntManagIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>{sidebarpagetext.Restaurant_Mngmnt_text}</p>
                </div>
              </li>
            </Link>
            {/**-----------------------Reservation Management--------------------------- */}
            <Link onClick={() => handleLinkClick("/View_Reservation")}>
              <li
                className={`reservation_mngmt ${Triosidebarhideshow(
                  "Reservation_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Reservation"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Reservation"
                        ? ReserVationIcnRed
                        : ReserVationIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>{sidebarpagetext.Rsrvtn_Mngmnt_text}</p>
                </div>
              </li>
            </Link>
            {/**---------------------Restaurent Enquiry-------------- */}
            <Link to="/View_Restaurant_enquiry">
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Guest_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Restaurant_enquiry"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Restaurant_enquiry"
                        ? RestEquiryRed
                        : RestEquiry
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>Restaurant Enquiry</p>
                </div>
              </li>
            </Link>
            {/**------------------------User Managment / Guest Management-------------------- */}
            <Link onClick={() => handleLinkClick("/View_Guest")}>
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Guest_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Guest" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Guest"
                        ? UserMangeIcnRed
                        : UserMangeIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>{sidebarpagetext.Guest_Mgmt_text}</p>
                </div>
              </li>
            </Link>
            {/**------------------------Staff Management-------------------- */}
            <li className="dropdown sidebarDropdown staff_mngmt">
              <div
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className={`sidebar_list_item dropdown-toggle ${
                  location.pathname === "/View_Staff" ||
                  location.pathname === "/Add_Staff" ||
                  location.pathname === "/View_Staff_Rights" ||
                  location.pathname === "/View_Sub_Rights" ||
                  location.pathname === "/View_Inside_Page_Rights" ||
                  location.pathname === "/Create_Staff_Rights" ||
                  location.pathname === "/Edit_Staff_Rights" ||
                  location.pathname === "/Edit_Sub_Rights" ||
                  location.pathname === "/Create_Inside_Page_Rights" ||
                  location.pathname === "/ViewDesignation" ||
                  location.pathname.includes("/edit_staff")
                    ? "activeSideItem"
                    : " "
                }`}
              >
                <img
                  src={
                    location.pathname === "/View_Staff" ||
                    location.pathname === "/Add_Staff" ||
                    location.pathname === "/View_Staff_Rights" ||
                    location.pathname === "/View_Sub_Rights" ||
                    location.pathname === "/View_Inside_Page_Rights" ||
                    location.pathname === "/Create_Staff_Rights" ||
                    location.pathname === "/Edit_Staff_Rights" ||
                    location.pathname === "/Edit_Sub_Rights" ||
                    location.pathname === "/Create_Inside_Page_Rights" ||
                    location.pathname === "/ViewDesignation" ||
                    location.pathname.includes("/edit_staff")
                      ? StaffMangIcnRed
                      : StaffMangIcn
                  }
                  alt="Dfoodo Dashboard"
                />
                <p>{sidebarpagetext.Staff_Mgmt_text}</p>
                <div className="droparrow">
                  <img
                    src={
                      location.pathname === "/View_Staff" ||
                      location.pathname === "/Add_Staff" ||
                      location.pathname === "/View_Staff_Rights" ||
                      location.pathname === "/View_Sub_Rights" ||
                      location.pathname === "/View_Inside_Page_Rights" ||
                      location.pathname === "/Create_Staff_Rights" ||
                      location.pathname === "/Edit_Staff_Rights" ||
                      location.pathname === "/Edit_Sub_Rights" ||
                      location.pathname === "/Create_Inside_Page_Rights" ||
                      location.pathname === "/ViewDesignation" ||
                      location.pathname.includes("/edit_staff")
                        ? dropArrowwOrange
                        : dropArroww
                    }
                    alt="img"
                  ></img>
                </div>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/View_Staff">
                    View Staff
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ViewDesignation">
                    Create Designation
                  </Link>
                </li>
              </ul>
            </li>
            {/**-------------------------Master Setup----------------------- */}
            <li className="dropdown sidebarDropdown resraurant_setup">
              <div
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className={`sidebar_list_item ${
                  location.pathname === "/collection_view" ||
                  location.pathname === "/cuisines_view" ||
                  location.pathname === "/amenities_view" ||
                  location.pathname === "/city_view" ||
                  location.pathname === "/country_view" ||
                  location.pathname === "/cuisines_add" ||
                  location.pathname === "/amenities_add" ||
                  location.pathname === "/collection_add" ||
                  location.pathname === "/city_add" ||
                  location.pathname.includes("/collection_edit") ||
                  location.pathname.includes("/cuisines_edit") ||
                  location.pathname.includes("/amenities_edit")
                    ? "activeSideItem"
                    : " "
                }`}
              >
                <img
                  src={
                    location.pathname === "/collection_view" ||
                    location.pathname === "/cuisines_view" ||
                    location.pathname === "/amenities_view" ||
                    location.pathname === "/city_view" ||
                    location.pathname === "/country_view" ||
                    location.pathname === "/cuisines_add" ||
                    location.pathname === "/amenities_add" ||
                    location.pathname === "/collection_add" ||
                    location.pathname === "/city_add" ||
                    location.pathname.includes("/collection_edit") ||
                    location.pathname.includes("/cuisines_edit") ||
                    location.pathname.includes("/amenities_edit")
                      ? MasterSetupIcnRed
                      : MasterSetupIcn
                  }
                  alt="Dfoodo Dashboard"
                />
                <p>{sidebarpagetext.Restaurant_Setup_text}</p>
                <div className="droparrow">
                  <img src={dropArroww} alt="img"></img>
                </div>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/collection_view">
                    Category
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/cuisines_view">
                    Cuisines
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/amenities_view">
                    Amenities
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/city_view">
                    City
                  </Link>
                </li>
              </ul>
            </li>
            {/**-----------------------Arera Managemnet------------------ */}
            <Link onClick={() => handleLinkClick("/View_landmark")}>
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Subscription_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_landmark"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_landmark"
                        ? AreaManageIcnRed
                        : AreaManageIcn
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Landmark_Mgmt_text}</p>
                </div>
              </li>
            </Link>
            {/**--------------------Notification------------------------ */}
            <Link onClick={() => handleLinkClick("/view_notification")}>
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Subscription_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_notification"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_notification"
                        ? NotifiicnRed
                        : Notifiicn
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Notification_Mgmt_text}</p>
                </div>
              </li>
            </Link>
            {/**----------------------------Reports-------------------------- */}

            <Link to="/View_Reports">
              <li
                className={`reports sidebarDropdown ${Triosidebarhideshow(
                  "Reports"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Reports"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Reports"
                        ? ReportIcnRed
                        : ReportIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>Reports</p>
                </div>
              </li>
            </Link>

            <Link to="/Website_Management">
              <li
                className={`website_mngmt ${Triosidebarhideshow(
                  "Website_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Website_Management"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Website_Management"
                        ? WebMangrIcnRed
                        : WebMangrIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>{sidebarpagetext.Website_Mngmt}</p>
                </div>
              </li>
            </Link>

            <Link to="/Manuals">
              <li className="Manuals">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Manuals" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Help_And_Support" ||
                      location.pathname === "/FAQs" ||
                      location.pathname === "/Manuals"
                        ? HelpIcnRed
                        : HelpIcn
                    }
                    alt="Dfoodo Dashboard"
                  />
                  <p>{sidebarpagetext.Help_Support_text}</p>
                </div>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
