import React, { useState, useEffect, useRef } from "react";
import pncil from "../assets/pncil.svg";
import { retrieveData } from "../LocalConnection/LocalConnection";
import {
  handleError,
  handleSuccess,
  validateMobileplus,
} from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep12 = ({ nextStep, prevStep, handlePrevStepcustom }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [activeSection, setActiveSection] = useState("about");
  const [activeEdit, setactiveEdit] = useState("about");
  const [restaurantName, setRestaurantName] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [PerDayMaximum, setPerDayMaximum] = useState("");
  const [ApprovalPersonLimit, setApprovalPersonLimit] = useState("");
  const [BeforeBookingMin, setBeforeBookingMin] = useState("");
  const [TableTurnover, setTableTurnover] = useState("");
  const [RestaurantPrice, setRestaurantPrice] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // Single value for radio selection
  const [fssaiNumber, setFssaiNumber] = useState("");
  const [gstinNumber, setGstinNumber] = useState("");
  const [cuisines, setCuisines] = useState("");
  const [address, setAddress] = useState({
    location: "",
    street: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  });
  const [contactInfo, setContactInfo] = useState({
    countryCode: "+91",
    phoneNumber: "",
    email: "",
  });
  const [isEditable, setIsEditable] = useState({
    name: false,
    description: false,
    contactDetails: false,
    location: false,
    features: false,
    ratings: false,
  });
  const restaurantInfo = {
    name: "Restaurant Information",
    description: "Restaurant Description.",
    averagePrice: "Average Price for Two Person",
    contactDetails: "Contact Details",
    restImg: "Restaurant Image",
    restMenuImg: "Restaurant Menu",
    OperationHours: "Operation Hours",
    location: "Location",
    features: "Restaurant Features",
    ratings: "Cuisines ",
    seat: "Seat ",
    maxseat: "Per Day Max. Seat ",
    approvelimit: "Approval Person Limit ",
    bookingcut: "Booking Cutoff Time",
    tableturnover: "Table Turn Over Time",
    reservationslot: "Manage Reservation with Time Slots of",
    fssanumber: "Restaurant Document",
    gstinnumber: "GSTIN Document",
  };

  const sections = [
    { id: "about", label: "About" },
    { id: "contact", label: "Contact" },
    { id: "location", label: "Location" },
    { id: "details", label: "Restaurant Details" },
    { id: "hours", label: "Hours" },
    { id: "seat", label: "Seat" },
  ];

  const sectionRefs = useRef(sections.map(() => React.createRef()));
  const containerRef = useRef(null);

  const handleEditClick = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
    setactiveEdit(field);
    setTimeout(() => {
      const inputField = document.querySelector(`.editable-${field}`);
      if (inputField) {
        inputField.focus();
      }
    }, 0);

    setIsEditing(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            setActiveSection(sectionId);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_name", restaurantName);
    form_data.append("restaurant_tagline", additionalInfo);
    form_data.append("per_day_maximum", PerDayMaximum);
    form_data.append("approval_person_limit", ApprovalPersonLimit);
    form_data.append("before_booking_min", BeforeBookingMin);
    form_data.append("booking_slot_interval", selectedOption);
    form_data.append("table_turnover", TableTurnover);
    form_data.append("restaurant_price", RestaurantPrice);
    form_data.append("restaurant_mobile_no", contactInfo.phoneNumber);
    form_data.append("restaurant_other_no", contactInfo.phoneNumber);
    form_data.append("restaurant_country_code", contactInfo.countryCode);
    form_data.append("restaurant_email", contactInfo.email);
    form_data.append("restaurant_fssai", fssaiNumber);
    form_data.append("restaurant_gstin", gstinNumber);
    form_data.append("restaurant_full_adrress", address.location);
    form_data.append("restaurant_temorary_adrress", address.street);
    form_data.append("restaurant_city", address.city);
    form_data.append("restaurant_state", address.state);
    form_data.append("restaurant_country", address.country);
    form_data.append("restaurant_zipcode", address.zip);
    form_data.append("step_save", "12");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  const handleInputChange = (e) => {
    setRestaurantName(e.target.value);
  };
  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setRestaurantName(
              Response.data.message.restaurant_data[0].restaurant_name
            );
            setAdditionalInfo(
              Response.data.message.restaurant_data[0].restaurant_tagline
            );
            setPerDayMaximum(
              Response.data.message.restaurant_data[0].per_day_maximum
            );
            setApprovalPersonLimit(
              Response.data.message.restaurant_data[0].approval_person_limit
            );
            setBeforeBookingMin(
              Response.data.message.restaurant_data[0].before_booking_min
            );
            setTableTurnover(
              Response.data.message.restaurant_data[0].table_turnover
            );
            setSelectedOption(
              Response.data.message.restaurant_data[0].booking_slot_interval
            );
            setRestaurantPrice(
              Response.data.message.restaurant_data[0].restaurant_price
            );
            setContactInfo({
              countryCode:
                Response.data.message.restaurant_data[0]
                  .restaurant_country_code !== null
                  ? Response.data.message.restaurant_data[0]
                      .restaurant_country_code
                  : "+91",
              phoneNumber:
                Response.data.message.restaurant_data[0]
                  .restaurant_mobile_no === null
                  ? ""
                  : Response.data.message.restaurant_data[0]
                      .restaurant_mobile_no,
              email:
                Response.data.message.restaurant_data[0].restaurant_email ===
                null
                  ? ""
                  : Response.data.message.restaurant_data[0].restaurant_email,
            });
            setFssaiNumber(
              Response.data.message.restaurant_data[0].restaurant_fssai
            );
            setGstinNumber(
              Response.data.message.restaurant_data[0].restaurant_gstin
            );
            setAddress({
              location:
                Response.data.message.restaurant_data[0]
                  .restaurant_full_adrress,
              street:
                Response.data.message.restaurant_data[0]
                  .restaurant_temorary_adrress,
              country:
                Response.data.message.restaurant_data[0].restaurant_country,
              city: Response.data.message.restaurant_data[0].restaurant_city,
              state: Response.data.message.restaurant_data[0].restaurant_state,
              zip: Response.data.message.restaurant_data[0].restaurant_zipcode,
            });
            const cuisinesString = Response.data.message.cuisineselected
              .map((option) => option.cuisine_name)
              .join(",");

            setCuisines(cuisinesString);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTabClick = (sectionId, index) => {
    setActiveSection(sectionId);
    sectionRefs.current[index].current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let value_ddd = value;
    if (name === "countryCode") {
      value_ddd = validateMobileplus(value);
    }
    setContactInfo((prev) => ({ ...prev, [name]: value_ddd }));
  };

  const handleMobilevalidation = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber" && !/^\d*$/.test(value)) {
      return;
    }

    handleChange(e);
  };

  const handleRadioChange = (index) => {
    setSelectedOption(index); // Update selected option
  };
  const handleFssaiChange = (event) => {
    setFssaiNumber(event.target.value);
  };

  const handleGstinChange = (event) => {
    setGstinNumber(event.target.value);
  };

  const handleChangeAdress = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveData = () => {
    setIsEditing(false);
  };
  const contentMapping = {
    about: (
      <div className="AboutCont">
        <div
          className={`underContent ${activeEdit == "name" ? "backCOlor" : ""}`}
        >
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.name}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("name")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-name"
            id="name"
            type="text"
            defaultValue="XYZ Restaurant name"
            value={restaurantName}
            onChange={handleInputChange}
            disabled={!isEditable.name}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.description}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("description")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-description"
            type="text"
            value={additionalInfo}
            onChange={handleAdditionalInfoChange}
            disabled={!isEditable.description}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.averagePrice}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("averagePrice")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-averagePrice"
            type="text"
            value={RestaurantPrice}
            onChange={(e) => setRestaurantPrice(e.target.value)}
            disabled={!isEditable.averagePrice}
          />
        </div>
      </div>
    ),
    contact: (
      <div className="contactUs">
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.contactDetails}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("contactDetails")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-contactDetails"
            type="text"
            value={contactInfo.phoneNumber}
            onChange={handleMobilevalidation}
            disabled={!isEditable.contactDetails}
          />
        </div>
      </div>
    ),
    location: (
      <div className="contactUs">
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.location}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("location")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-location"
            type="text"
            value={address.location}
            onChange={handleChangeAdress}
            disabled={!isEditable.location}
          />
        </div>
      </div>
    ),
    details: (
      <div className="contactUs">
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.ratings}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handlePrevStepcustom(7)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            type="text"
            className="form-control"
            value={cuisines}
            disabled={!isEditable.features}
          />
        </div>

        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.restImg}</p>
            <img
              src={pncil}
              alt="pncil"
              style={{ cursor: "pointer" }}
              onClick={() => handlePrevStepcustom(10)}
            />
          </div>
          <label>Restaurant Menu will be shown on Dfoodo platform.</label>
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.restMenuImg}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handlePrevStepcustom(11)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <label>Restaurant Menu will be show on Dfoodo platform.</label>
        </div>
      </div>
    ),
    hours: (
      <div className="contactUs">
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.OperationHours}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handlePrevStepcustom(9)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control"
            type="text"
            defaultValue="Operation Hours will help for the order Discount"
            disabled={!isEditable.OperationHours}
          />
        </div>
      </div>
    ),
    seat: (
      <div className="contactUs">
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.maxseat}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("maxseat")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-maxseat"
            type="text"
            value={PerDayMaximum}
            onChange={(e) => setPerDayMaximum(e.target.value)}
            disabled={!isEditable.maxseat}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.approvelimit}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("approvelimit")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-approvelimit"
            type="text"
            value={ApprovalPersonLimit}
            onChange={(e) => setApprovalPersonLimit(e.target.value)}
            disabled={!isEditable.approvelimit}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.bookingcut}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("bookingcut")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-bookingcut"
            type="text"
            value={BeforeBookingMin}
            onChange={(e) => setBeforeBookingMin(e.target.value)}
            disabled={!isEditable.bookingcut}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.tableturnover}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("tableturnover")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-tableturnover"
            type="text"
            value={TableTurnover}
            onChange={(e) => setTableTurnover(e.target.value)}
            disabled={!isEditable.tableturnover}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.reservationslot}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("reservationslot")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-9">
            <div className="ContainerCheckbox">
              <div className="timeSLots">
                <input
                  className="mt-0"
                  type="radio"
                  checked={selectedOption === 15}
                  disabled={!isEditable.reservationslot}
                  onChange={() => handleRadioChange(15)}
                />
                <p style={{ marginLeft: "5px" }}>15 minutes</p>
              </div>
              <div className="timeSLots">
                <input
                  className="mt-0"
                  type="radio"
                  checked={selectedOption === 30}
                  disabled={!isEditable.reservationslot}
                  onChange={() => handleRadioChange(30)}
                />
                <p style={{ marginLeft: "5px" }}>30 minutes</p>
              </div>
            </div>
          </div>
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.fssanumber}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("fssanumber")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-fssanumber"
            type="text"
            defaultValue="Fssai Number"
            onChange={handleFssaiChange}
            value={fssaiNumber}
            disabled={!isEditable.fssanumber}
          />
        </div>
        <div className="underContent">
          <div className="d-flex align-items-center">
            <p>{restaurantInfo.gstinnumber}</p>
            <img
              src={pncil}
              alt="pncil"
              onClick={() => handleEditClick("gstinnumber")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <input
            className="form-control editable-gstinnumber"
            type="text"
            onChange={handleGstinChange}
            value={gstinNumber}
            defaultValue="GSTIN Number"
            disabled={!isEditable.gstinnumber}
          />
        </div>
      </div>
    ),
  };

  return (
    <>
      <div className="overflowHeight12">
        <div className="contFOrm">
          <h2 className="headingResto">Restaurant Information</h2>
          <div className="section-buttons">
            {sections.map((section, index) => (
              <button
                key={section.id}
                onClick={() => handleTabClick(section.id, index)}
                className={`section-button ${
                  activeSection === section.id ? "active" : ""
                }`}
              >
                {section.label}
              </button>
            ))}
          </div>
          <div
            ref={containerRef}
            className="content-container"
            style={{ overflowY: "scroll" }}
          >
            {sections.map((section, index) => (
              <div
                key={section.id}
                ref={sectionRefs.current[index]}
                id={section.id}
                className="content-box"
              >
                {contentMapping[section.id]}
              </div>
            ))}
          </div>
        </div>
        <div className="footerbtttn Subbbtn">
          <button
            className={`SvBtn ${isEditing ? "editingClass" : ""}`}
            onClick={isEditing ? handleSaveData : null}
            style={{
              opacity: isEditing ? 1 : 0.5,
              cursor: isEditing ? "pointer" : "not-allowed",
            }}
            disabled={!isEditing}
          >
            Save
          </button>

          <button
            className="SubmtBtN"
            onClick={() => handleSaveChangesdynamic()}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Onboadingstep12;
