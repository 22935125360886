import React from "react";
import successPaymentGif from "../assets/successPaymentGif.gif";

const Onboadingstep13 = () => {
  return (
    <>
      <div
        className="contFOrm text-center"
        style={{ boxShadow: "0px 4px 18px 0px #00000040" }}
      >
        <div className="succSSimg">
          <img src={successPaymentGif} alt="successPaymentGif"></img>
        </div>
        <h4 className="thnkMsg">Thank You</h4>
        <div className="flex flex-col items-center">
          <p className="text-lg font-semibold Scndhead">
            You have successfully register on DFOODO{" "}
          </p>
          <p className="text-gray-600 thirdhead">Our Team will connect soon </p>
          {/* <p className="text-red-500" style={{ color: "#E33745" }}>
            Support@dfoodo.com
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Onboadingstep13;
