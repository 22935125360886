import React, { useState, useEffect } from "react";
import teamimage from "../assets/addImgImg.png";
import delicon from "../assets/delete.svg";
import update from "../assets/editBlack.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Button } from "react-bootstrap";
import Loader from "./Loader.js";
import bckIcn from "../assets/bckIcn.svg";
import blankUserImg from "../assets/blankUserImg.png";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleIaphabetnumberChange,
  DateormateBlogChange,
  handleAphabetsChange,
  handleSuccess,
  handleURLChange,
} from "../CommonJquery/CommonJquery";
import {
  APL_LINK,
  server_post_data,
  save_update_blog,
  save_update_testimonial,
  get_all_testimonial,
  delete_website_master_data,
  get_all_blogs,
} from "../ServiceConnection/serviceconnection.js";
import { BlogWebsite_text } from "../CommonJquery/WebsiteText";

const ViewTestimonial = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [SelectedData, setSelectedData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [error_show, seterror_show] = useState("");
  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  useEffect(() => { }, [editStaffData]);
  const handleDeactivate = (SelectedData) => {
    master_data_action_update(SelectedData.primary_id, "d");
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };

  const handleEdit = (data_call) => {
    master_data_get("", "", "3", data_call.primary_id);
  };
  const handleEditorChange = (event, editor) => {
    setEditorData(editor.getData());
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_testimonial, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data_testimonial);
          if (Response.data.message.data_testimonial.length > 0) {
            if (flag === "3") {
              seteditStaffDataDetails(Response.data.message.data_testimonial[0]);
              setEditorDatMainID(Response.data.message.data_testimonial[0].primary_id);
              setEditorData(Response.data.message.data_testimonial[0].testimonial_image);
              setFormVisible(true);
            } else {
              seteditStaffDataDetails([]);
            }
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error)
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", "24");
    fd.append("for_status_final", for_status_final);
    await server_post_data(delete_website_master_data, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    console.log(vaild_data)
    // if (vaild_data) {

    setshowLoaderAdmin(true);
    let fd_from = combiled_form_data(form_data, null);
    fd_from.append("main_id", editorDataMainID);
    fd_from.append("testimonial_image", editorData);
    // fd_from.append("flag", editorData);
    await server_post_data(url_for_save, fd_from)
      .then((Response) => {
        console.log(Response.data.message);
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get("", "", "1", "0");
          handleSuccess(Response.data.message);

          setFormVisible(false);
          empty_form(form_data);
        }
      })
      .catch((error) => {
        console.log(error);
        setshowLoaderAdmin(false);
      });

    // }
  };
  const handleCloseAddEditBack = () => {
    setFormVisible(false);
  };
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageError = (e) => {
    e.target.src = blankUserImg;
  };
  return (
    <div style={{ overflow: "hidden" }}>
      {showLoaderAdmin && <Loader />}
      {!formVisible && (
        <>
          <div className="headSeo justify-content-end">
            <button
              type="button"
              onClick={handleToggleForm}
              className="btn btn-secondary"
            >
              Add Testimonial
            </button>
          </div>
          <div className="seoTable_container stickyTableheigh265">
            <table className="table">
              <thead className="stickyHeaderClss">
                <tr>
                  <th
                    style={{ color: "var(--primary-color)", fontWeight: "600" }}
                  >
                    Sr.No
                  </th>
                  <th
                    style={{ color: "var(--primary-color)", fontWeight: "600" }}
                  >
                    Auther Img
                  </th>
                  <th
                    style={{ color: "var(--primary-color)", fontWeight: "600" }}
                  >
                    Auther Name
                  </th>

                  <th
                    style={{ color: "var(--primary-color)", fontWeight: "600" }}
                  >
                    Restaurant
                  </th>
                  <th
                    style={{ color: "var(--primary-color)", fontWeight: "600" }}
                  >
                    City
                  </th>
                  <th
                    style={{ color: "var(--primary-color)", fontWeight: "600" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {editStaffData &&
                  editStaffData.length > 0 &&
                  editStaffData.map((option, index) => (
                    <React.Fragment key={index}>
                      <tr className="seoTable_row">
                        <td className="col-xs serial-span">
                          <div>
                            <span className="indexNumber">{1 + index}</span>
                          </div>
                        </td>
                        <td>
                          <img
                            src={APL_LINK + "/assets/" + option.testimonial_image}
                            onError={(e) => (e.target.src = teamimage)}
                            alt="Dfoodo Dashboard"
                          />
                        </td>
                        <td className="col-md">
                          <div>{option.auth_name}</div>
                        </td>
                        <td>
                          <div>{option.restaurant_name}</div>
                        </td>
                        <td>{option.city}</td>
                        <td>
                          <div className="img-icons mb-4">
                            <img
                              onClick={() => handleShow(option)}
                              className="del-icon"
                              src={delicon}
                              alt="delicon"
                              style={{ width: "2.5rem", cursor: "pointer" }}
                            />
                            <img
                              onClick={() => handleEdit(option)}
                              className=""
                              src={update}
                              alt="update"
                              style={{ width: "2.5rem", cursor: "pointer" }}
                            />
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {/*---------------------------Add Testimonial Form Show-----------------------------*/}
      {formVisible && (
        <form id="addNewStaff">
          <div className="event-box blog-data">
             {/*<div className="d-flex  justify-content-between align-items-center">
              <button
                className="BckBttn btn btn-secondary"
                onClick={handleCloseAddEditBack}
              >
                <img src={bckIcn} alt="back" title="back"></img> Back
              </button>

              <label>Add Testimonial</label>
            </div> */}

          <div className="form-group mt-4 AddEditTesti">
            <div className="row m-0">
              <div className="col-md-12">
                <label>Auther Image</label>
                {/* <input
                    type="text"
                    name="img_url"
                    id="img_url"
                    defaultValue={editStaffDataDetails.image_name || ""}
                    onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Image URL*"
                  />
                  <span className="condition_error"></span> */}{" "}
                <div
                  className="testiImgUpload"
                  onClick={() => document.getElementById("img_url").click()}
                >
                  <img
                    src={image || blankUserImg}
                    alt="Uploaded"
                    onError={handleImageError}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />

                  <input
                    type="file"
                    name="testi_image_new"
                    id="img_url"
                    style={{ display: "none" }}
                    className="form-control trio_mandatory"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>{" "}
                {/* <p className="autherimgTxt">Auther Image</p> */}
                <span className="condition_error"></span>
              </div>
              <div className="col-md-4 mt-3">
                <label>Auther Name</label>
                <input
                  type="text"
                  name="auth_name"
                  id="img_url_mobile"
                  defaultValue={editStaffDataDetails.auth_name || ""}
                  className="form-control trio_mandatory"
                  placeholder="Enter Auther Name*"
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-4 mt-3">
                <label>Restaurant </label>
                <input
                  type="text"
                  id="author_data"
                  defaultValue={editStaffDataDetails.restaurant_name || ""}
                  name="restaurant_name"
                  onInput={handleIaphabetnumberChange}
                  className="form-control trio_mandatory"
                  placeholder="Enter Restaurant Name*"
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-4 mt-3">
                <label> City</label>
                <input
                  type="text"
                  name="city"
                  defaultValue={editStaffDataDetails.city || ""}
                  id="title_data"
                  onInput={handleAphabetsChange}
                  className="form-control trio_mandatory"
                  placeholder="Enter City*"
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-12 mt-3">
                <label> Message</label>
                <textarea
                  className="textareBlogs form-control trio_mandatory "
                  name="message"
                  id="tagline_data"
                  defaultValue={editStaffDataDetails.message || ""}
                  onInput={handleIaphabetnumberChange}
                  placeholder="Enter Introduction*"
                ></textarea>
                <span className="condition_error"></span>
              </div>

              <div className="col-md-12 ">
                <div className="addBlogBtnDiv">
                  <button
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "addNewStaff",
                        save_update_testimonial
                      )
                    }
                    className="btn btn-secondary mt-3 save-cap-btn"
                  >
                    {BlogWebsite_text.save_txt}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
  )
}
<Modal
  show={modalShow}
  className="confirmModal"
  centered
  backdrop="static"
  keyboard={false}
  onHide={handleClose}
>
  <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
  <Modal.Body className="confirmBody">
    <p className="modalText">Do You Want to Delete This Data?</p>
  </Modal.Body>
  <Modal.Footer className="confirmModalFooter">
    <Button className={` logoutNoBtn`} onClick={handleClose}>
      {BlogWebsite_text.no_txt}
    </Button>
    <Button className={` logoutYesBtn`} onClick={() => handleDeactivate(SelectedData)}>
      <label
        style={{ cursor: "pointer" }}
        htmlFor={`dynamic_id${SelectedData.primary_id}`}
      >
        {BlogWebsite_text.Yes_txt}
      </label>
    </Button>
  </Modal.Footer>
</Modal>
    </div >
  );
};
export default ViewTestimonial;
